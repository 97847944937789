import {getFirebaseBackend} from "./firebase_helper";
import moment from "moment";

const firebase = getFirebaseBackend().firebase;

export const selectTransactionFromDB = async (id, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to access this company');
	}

	let data = await firebase.firestore().collection('companies').doc(company).collection('transactions').doc(id).get()

	return {
		...data.data(),
		id: data.id
	}
}

export const getTransactionsFromDB = async (user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to access this company');
	}

	let docQuery = await firebase.firestore().collection('companies').doc(company).collection('transactions');
	if(user.role === 'user') {
		const today = new Date();
		today.setHours(0,0,0,0);
		docQuery = await docQuery.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(today));
	}

	const list = await docQuery.get();

	return list.docs.map((doc) => {
		return {
			id: doc.id,
			...doc.data(),
		}
		});
}

export const createTransactionInDB = async (data, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
	data.amount = parseInt(data.amount);
	data.debtDate = data.debtDate ? moment(data.debtDate).format('YYYY-MM-DD') : moment(data.debtDate).format('YYYY-MM-DD');

	const collection = await firebase.firestore().collection('companies').doc(company).collection('transactions');
	const docRef = collection.doc();
	await docRef.set(data);

	const clientRef = firebase.firestore().collection('companies').doc(company).collection('clients').doc(data.client.value);
	const companyRef = await firebase.firestore().collection('companies').doc(company);
	const companyDailyStatsRef = companyRef.collection('stats').doc(data.debtDate);

	if(data.type === 'debt') {
		await companyRef.update({
			totalDue: firebase.firestore.FieldValue.increment(data.amount),
		});

		const companyDailyStatsDoc = await companyDailyStatsRef.get();
		if(companyDailyStatsDoc.exists) {
			companyDailyStatsRef.update({
				totalDue: firebase.firestore.FieldValue.increment(data.amount),
			});
		} else {
			companyDailyStatsRef.set({
				totalDue: data.amount,
				date: new Date(data.debtDate),
			});
		}

		await clientRef.update({
			totalDue: firebase.firestore.FieldValue.increment(data.amount),
		});
	}

	// update company total paid and daily paid amounts
	if(data.type === 'payment') {
		await companyRef.update({
			totalPaid: firebase.firestore.FieldValue.increment(data.amount),
		});

		const companyDailyStatsDoc = await companyDailyStatsRef.get();
		if(companyDailyStatsDoc.exists) {
			companyDailyStatsRef.update({
				totalPaid: firebase.firestore.FieldValue.increment(data.amount),
			});
		} else {
			companyDailyStatsRef.set({
				totalPaid: data.amount,
				date: new Date(data.debtDate),
			});
		}
	}

	if(data.type === 'payment' && !data.eventId) {
		await clientRef.update({
			totalPaid: firebase.firestore.FieldValue.increment(data.amount),
		});

		const debts = await firebase.firestore().collection('companies')
			.doc(company)
			.collection('transactions')
			.where('type', '==', 'debt')
			.where('client.value', '==', data.client.value)
			.where('paid', '==', false)
			.orderBy('createdAt', 'asc')
			.get();

		const debtsToUpdate = [];
		const events = [];
		let totalPaid = data.amount;

		debts.forEach((debt) => {
			const debtData = debt.data();
			const debtAmount = debtData.amount - (debtData.paidAmount || 0);

			events.push(debtData.eventId);

			if(totalPaid >= debtAmount) {
				totalPaid -= debtAmount;
				debtsToUpdate.push({
					ref: debt.ref,
					data: {
						paid: true,
						paidAt: firebase.firestore.FieldValue.serverTimestamp(),
						paidAmount: debtAmount
					}
				});
			} else {
				if(totalPaid > 0) {
					debtsToUpdate.push({
						ref: debt.ref,
						data: {
							paidAmount: firebase.firestore.FieldValue.increment(totalPaid)
						}
					});

					totalPaid = 0;
				}
			}
		});

		debtsToUpdate.forEach((debt) => {
			debt.ref.update(debt.data);
		});

		let priceToPay = data.amount;

		for (let j = 0; j < events.length; j++) {
			if(priceToPay <= 0) break;

			const eventRef = firebase.firestore().collection('companies').doc(company).collection('events').doc(events[j]);
			const event = await eventRef.get();
			const eventData = event.data();

			if(eventData.paidAmount + priceToPay >= eventData.price) {
				eventRef.update({
					paid: true,
					paidAt: firebase.firestore.FieldValue.serverTimestamp(),
					paidAmount: eventData.price,
				});

				priceToPay -= eventData.price - eventData.paidAmount;
			} else {
				eventRef.update({
					paidAmount: firebase.firestore.FieldValue.increment(data.amount),
				});

				priceToPay = 0;
			}
		}
	}

	const docData = await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data(),
			}
		} else {
			return null;
		}
	});

	return {
		data: docData
	}
}

export const getClientUnpaidTransactionsFromDB =	async (clientId, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const list = await firebase.firestore()
		.collection('companies')
		.doc(company)
		.collection('transactions')
		.where('client.value', '==', clientId)
		.where('paid', '==', false)
		.orderBy('createdAt', 'desc')
		.get();

	return list.docs.map((doc) => {
		return {
			id: doc.id,
			...doc.data(),
		}
		});
};

export const getClientTransactionsFromDB =	async (clientId, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const list = await firebase.firestore()
		.collection('companies')
		.doc(company)
		.collection('transactions')
		.where('client.value', '==', clientId)
		.orderBy('createdAt', 'desc')
		.get();

	return list.docs.map((doc) => {
		return {
			id: doc.id,
			...doc.data(),
		}
		});
};

