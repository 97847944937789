import {
	GET_COMPANIES,
	GET_COMPANIES_SUCCESS,
	GET_COMPANIES_FAILED,
	CREATE_COMPANY_FAILED,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY,
	DESELECT_COMPANY,
	CREATE_COMPANY_USER_SUCCESS,
	CREATE_COMPANY_USER,
	CREATE_COMPANY_USER_FAILED,
	SELECT_LOGGED_IN_COMPANY,
	SELECT_LOGGED_IN_COMPANY_FAILED,
	SELECT_LOGGED_IN_COMPANY_SUCCESS,
	UPDATE_LOGGED_IN_COMPANY_FAILED,
	UPDATE_LOGGED_IN_COMPANY,
	UPDATE_LOGGED_IN_COMPANY_SUCCESS,
	SELECT_COMPANY,
	SELECT_COMPANY_SUCCESS,
	SELECT_COMPANY_FAILED,
	UPDATE_COMPANY,
	UPDATE_COMPANY_FAILED,
	UPDATE_COMPANY_SUCCESS,
	CLOSE_DAY,
	CLOSE_DAY_FAILED,
	CLOSE_DAY_SUCCESS
} from "./actionTypes";

const initialState = {
	companies: [],
	selected: {},
	selectedCompany: {},
	loggedInCompany: {},
	loading: false,
	error: false,
	errorMessage: "",
	tab: 1,
}
const company = (state = initialState, action) => {
	switch (action.type) {
		case GET_COMPANIES:
			return {
				...state,
				loading: true,
				error: false,
		}
		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				companies: action.payload,
		}
		case GET_COMPANIES_FAILED:
			return {
				...state,
				loading: false,
				error: true,
		}

		case SELECT_COMPANY:
			return {
				...state,
				loading: true,
				error: false,
		}

		case SELECT_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				selectedCompany: action.payload,
		}

		case SELECT_COMPANY_FAILED:
			return {
				...state,
				loading: false,
				error: true,
				errorMessage: action.payload,
		}

		case CREATE_COMPANY:
			return {
				...state,
				loading: true,
				error: false,
		}
		case CREATE_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				companies: [...state.companies, action.payload.details],
				selected: action.payload.details,
				tab: 2,
		}
		case CREATE_COMPANY_FAILED:
			return {
				...state,
				loading: false,
				error: true,
		}
		case DESELECT_COMPANY:
			return {
				...state,
				tab: 1,
				selected: {},
		}
		case CREATE_COMPANY_USER:
			return {
				...state,
				error: false,
				loading: true,
		}
		case CREATE_COMPANY_USER_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				tab: 3,
		}
		case CREATE_COMPANY_USER_FAILED:
			return {
				...state,
				loading: false,
				error: true,
		}
		case SELECT_LOGGED_IN_COMPANY:
			return {
				...state,
				loading: true,
				error: false,
		}
		case SELECT_LOGGED_IN_COMPANY_FAILED:
			return {
				...state,
				loading: false,
				error: true,
		}
		case SELECT_LOGGED_IN_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				loggedInCompany: action.payload,
		}
		case UPDATE_LOGGED_IN_COMPANY:
			return {
				...state,
				loading: true,
				error: false,
		}
		case UPDATE_LOGGED_IN_COMPANY_FAILED:
			return {
				...state,
				loading: false,
				error: true,
		}
		case UPDATE_LOGGED_IN_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				loggedInCompany: {
					...state.loggedInCompany,
					...action.payload,
				}
		}

		case UPDATE_COMPANY:
			return {
				...state,
				loading: true,
		}

		case UPDATE_COMPANY_FAILED:
			return {
				...state,
				loading: false,
				error: action.message,
		}

		case UPDATE_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				selectedCompany: {
					...state.selectedCompany,
					...action.payload,
				}
		}

		case CLOSE_DAY:
			return {
				...state,
				loading: true,
		}

		case CLOSE_DAY_FAILED:
			return {
				...state,
				loading: false,
				error: action.message,
		}

		case CLOSE_DAY_SUCCESS:
			return {
				...state,
				loading: false,
				loggedInCompany: {
					...state.loggedInCompany,
					closedDay: action.payload,
				}
		}
		default:
			return state;
	}
}

export default company;
