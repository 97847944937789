import { GET_SUBSCRIPTION_MODELS_FOR_CLASS, RESET_SUBSCRIPTION_FOR_CLASS } from "./actionTypes";

const defaultState = {
	loading: false,
}

const subscriptionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_MODELS_FOR_CLASS:
      return {
        ...state,
        loading: true,
      }

		case RESET_SUBSCRIPTION_FOR_CLASS:
			return {
				...state
		}
    default:
      return state;
  }
}

export default subscriptionsReducer;

