import { takeEvery, put, select, call } from 'redux-saga/effects';
import {
	CREATE_SERVICE_PROVIDER,
	CREATE_SERVICE_PROVIDER_FAILURE,
	CREATE_SERVICE_PROVIDER_SUCCESS,
	UPDATE_SERVICE_PROVIDER,
	UPDATE_SERVICE_PROVIDER_FAILURE,
	UPDATE_SERVICE_PROVIDER_SUCCESS,
	DELETE_SERVICE_PROVIDER,
	DELETE_SERVICE_PROVIDER_FAILURE,
	DELETE_SERVICE_PROVIDER_SUCCESS,
	GET_SERVICE_PROVIDERS,
	GET_SERVICE_PROVIDERS_FAILURE,
	GET_SERVICE_PROVIDERS_SUCCESS,
	UPDATE_SERVICE_PROVIDER_COLOR_FAILURE,
	UPDATE_SERVICE_PROVIDER_COLOR_SUCCESS,
	UPDATE_SERVICE_PROVIDER_COLOR,
} from "./actionTypes";
import {
	updateServiceProviderColorInDB,
} from "../../helpers/firebase";
import {toastr} from "../toastr/actions";
import { createServiceProviderApi, deleteServiceProviderApi, getServiceProvidersApi, updateServiceProviderApi } from '../../helpers/api/serviceProviders.api';

function* getServiceProviders() {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		let response = yield call(getServiceProvidersApi, company);

		yield put({ type: GET_SERVICE_PROVIDERS_SUCCESS, payload: response.serviceProviders })
	} catch (e) {
		yield put({ type: GET_SERVICE_PROVIDERS_FAILURE, payload: e })
	}
}

function* createServiceProvider({payload}) {
	try {

		let company = yield select(state => state.Company.loggedInCompany.ID);

		const response = yield call(createServiceProviderApi, company, payload.serviceProvider);
		yield put({ type: CREATE_SERVICE_PROVIDER_SUCCESS, payload: response.serviceProvider })
		yield put(toastr({
			type: 'success',
			title: 'Successfully created service provider',
		}));
	} catch (e) {
		yield put(toastr({
			type: 'danger',
			title: 'Error creating service provider',
		}));
		yield put({ type: CREATE_SERVICE_PROVIDER_FAILURE, payload: e })
	}
}

function* updateServiceProvider({payload}) {
	try {
		const companyId = yield select(state => state.Company.loggedInCompany.ID);

		yield call(updateServiceProviderApi, companyId, payload.serviceProvider.ID, payload.serviceProvider);

		yield put({ type: UPDATE_SERVICE_PROVIDER_SUCCESS, payload: payload.serviceProvider})
		yield put(toastr({
			type: 'success',
			title: 'Successfully updated service provider',
		}));
	} catch (e) {
		yield put(toastr({
			type: 'danger',
			title: 'Error updating service provider',
		}));
		yield put({ type: UPDATE_SERVICE_PROVIDER_FAILURE, payload: e })
	}
}

function* deleteServiceProvider({payload}) {
	try {
		const companyId = yield select(state => state.Company.loggedInCompany.ID);

		yield call(deleteServiceProviderApi, companyId, payload.id);
		yield put({ type: DELETE_SERVICE_PROVIDER_SUCCESS, payload: payload.id });
		yield put(toastr({
			type: 'success',
			title: 'Successfully deleted service provider',
		}));
	} catch (e) {
		yield put(toastr({
			type: 'danger',
			title: 'Error deleting this service provider',
		}));
		yield put({ type: DELETE_SERVICE_PROVIDER_FAILURE, payload: e });
	}
}

function* updateServiceProviderColor({payload}) {
	try{
		let user = yield select(state => state.Login.user);
		yield call(updateServiceProviderColorInDB, payload.id, payload.color, user);
		yield put({ type: UPDATE_SERVICE_PROVIDER_COLOR_SUCCESS, payload: payload })
	} catch(e) {
		yield put({ type: UPDATE_SERVICE_PROVIDER_COLOR_FAILURE, payload: e })
	}
}

export default function* serviceProvidersSaga() {
	yield takeEvery(GET_SERVICE_PROVIDERS, getServiceProviders);
	yield takeEvery(CREATE_SERVICE_PROVIDER, createServiceProvider);
	yield takeEvery(UPDATE_SERVICE_PROVIDER, updateServiceProvider);
	yield takeEvery(DELETE_SERVICE_PROVIDER, deleteServiceProvider);
	yield takeEvery(UPDATE_SERVICE_PROVIDER_COLOR, updateServiceProviderColor);
}
