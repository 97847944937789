import {
	takeEvery,
	takeLatest,
	select,
	put,
	call,
} from 'redux-saga/effects';

import { 
	GET_COSTS,
	GET_COSTS_FAILED,
	GET_COSTS_SUCCESS,
	CREATE_COST,
	DELETE_COST,
  CREATE_COST_FAILED,
  CREATE_COST_SUCCESS,
} from './actionTypes';

import { getCostsFromDB, createCostInDB} from '../../helpers/firebase';
import {toastr} from "../toastr/actions";

function* getCosts({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;

		if(!company) {
			company = user.companyId;
		}

		const costs = yield select(state => state.Costs.list);
		if(!costs) {
			const response = yield call(getCostsFromDB, user, company);
			yield put({type: GET_COSTS_SUCCESS, payload: response})
		} else {
			yield put({type: GET_COSTS_SUCCESS, payload: costs});
		}
	} catch (e) {
		yield put({type: GET_COSTS_FAILED, message: e.message})
	}
}

function* createCost({payload}) {
  try {
    let user = yield select(state => state.Login.user);
    let company = payload.company;
    if(!company) {
      company = user.companyId;
    }

    const response = yield call(createCostInDB, payload.data, user, company);
		
    yield put({type: CREATE_COST_SUCCESS, payload: response})
    yield put(toastr({
      title: 'Cost created successfully',
      type: 'success'
    }));
  } catch (e) {
    yield put(toastr({
      title: 'Cost creation failed, please try again.',
      type: 'error'
    }));
				console.log(e);
    yield put({type: CREATE_COST_FAILED, message: e.message})
  }
}

function* deleteCost() {
	yield console.log('deleteCost');
}

function* costsSaga() {
  yield takeLatest(GET_COSTS, getCosts);
  yield takeEvery(CREATE_COST, createCost);
  yield takeLatest(DELETE_COST, deleteCost);
}

export default costsSaga;

