import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Company from './companies/reducer';
import Locations from './locations/reducer';
import Calendar from './calendar/reducer';
import Services from './services/reducer';
import ServiceProviders from "./serviceProviders/reducer";
import Clients from "./clients/reducer";
import Toastr from "./toastr/reducer";
import Transactions from "./transactions/reducer";
import Costs from "./costs/reducer";
import Departments from "./departments/reducer";
import Purchases from "./purchases/reducer";
import Scheduler from "./scheduler/reducer";
import Reports from "./reports/reducer";
import EventReminders from "./eventReminders/reducer";
import Classes from "./classes/reducer";
import Groups from "./groups/reducer";
import Subscriptions from "./subscriptions/reducer";

const rootReducer = combineReducers({
	Layout,
	Login,
	Account,
	ForgetPassword,
	Profile,
	Company,
	Locations,
	Calendar,
	Services,
	ServiceProviders,
	Clients,
	Toastr,
	Transactions,
	Costs,
	Departments,
	Purchases,
	Scheduler,
	Reports,
	EventReminders,
	Classes,
	Groups,
	Subscriptions
});

export default rootReducer;
