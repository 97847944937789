import firebase from 'firebase/compat/app';

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	databaseURL: process.env.REACT_APP_DATABASEURL,
	projectId: process.env.REACT_APP_PROJECTID,
	storageBucket: process.env.REACT_APP_STORAGEBUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
	appId: process.env.REACT_APP_APPID,
	measurementId: process.env.REACT_APP_MEASUREMENTID,
};

class FirebaseAuthBackend {
	constructor(firebaseConfig) {
		if (firebaseConfig) {
			// Initialize Firebase
			firebase.initializeApp(firebaseConfig);
		}
	}

	firebase = firebase;

	initAuth = () => {
		return new Promise(resolve => {
			firebase.auth().onAuthStateChanged(user => {
			if (user) {
					sessionStorage.setItem("authUser", JSON.stringify(user));
				} else {
					sessionStorage.removeItem("authUser");
				}
			resolve();
			});
		});
	}
	/**
			* Registers the user with given details
	*/
	registerUser = (email, password) => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then(
					_ => {
						resolve(firebase.auth().currentUser);
					},
					error => {
						reject(this._handleError(error));
					}
			);
		});
	};

	getInvitation = async (token) => {
		const collection = firebase.firestore().collection("invites");
		const doc = await collection.doc(token).get();
		return doc.data();
	}

	inviteUser = async (data, role) => {
		const collection = firebase.firestore().collection("invites");
		const details = {
			...data,
			role: role,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		}

		await collection.add(details);

		return details;
	}

	/**
			* Registers the user with given details
	*/
	editProfileAPI = (email, password) => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then(
					user => {
						resolve(firebase.auth().currentUser);
					},
					error => {
						reject(this._handleError(error));
					}
			);
		});
	};

	/**
			* Login user with given details
	*/
	loginUser = (email, password) => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.signInWithEmailAndPassword(email, password)
				.then(
					user => {
						resolve(firebase.auth().currentUser);
					},
					error => {
						reject(this._handleError(error));
					}
			);
		});
	};

	/**
			* forget Password user with given details
	*/
	forgetPassword = email => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.sendPasswordResetEmail(email, {
					url:
					window.location.protocol + "//" + window.location.host + "/login",
				})
				.then(() => {
					resolve(true);
				})
				.catch(error => {
					reject(this._handleError(error));
			});
		});
	};

	/**
			* Logout the user
	*/
	logout = () => {
		return new Promise((resolve, reject) => {
			firebase
				.auth()
				.signOut()
				.then(() => {
					resolve(true);
				})
				.catch(error => {
					reject(this._handleError(error));
			});
		});
	};

	/**
			* Social Login user with given details
	*/
	socialLoginUser = async (type) => {
		let provider;
		if (type === "google") {
			provider = new firebase.auth.GoogleAuthProvider();
		} else if (type === "facebook") {
			provider = new firebase.auth.FacebookAuthProvider();
		}
	try {
			const result = await firebase.auth().signInWithPopup(provider);
			return result.user;
		} catch (error) {
			throw this._handleError(error);
		}
	};

	addUserToFirestore = async (user, role) => {
		const collection = firebase.firestore().collection("users");
		const details = {
			name: user.first_name,
			email: user.email,
			role: role,
			companyId: user.companyId ?? null,
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			lastLoginAt: firebase.firestore.FieldValue.serverTimestamp(),
			serviceProviderId: user.serviceProviderId ?? null,
		};
		await collection.doc(firebase.auth().currentUser.uid).set(details);
		return { user, details };
	};

	deleteInvitation = async (invitation) => {
		const collection = firebase.firestore().collection("invites");
		await collection.doc(invitation.id).delete();

		const companyCollection = firebase.firestore().collection("companies");
		const companyDoc = await companyCollection.doc(invitation.companyId).get();
		const companyDetails = companyDoc.data();
		if(!companyDetails) {
			return;
		}
		companyDetails.status = "INVITE_ACCEPTED";
		await companyCollection.doc(invitation.companyId).set(companyDetails);
	}

	getCompanies = async () => {
		const collection = firebase.firestore().collection("companies");
		const snapshot = await collection.get().then();
		return snapshot.docs.map(doc => {
			return {
				id: doc.id,
				...doc.data(),
			}
		});
	}

	selectCompany = async (id) => {
		const doc = await firebase.firestore().collection("companies").doc(id).get();

		if(!doc.exists) {
			return null;
		}

		return {
			id: doc.id,
			...doc.data(),
		}

	}

	createCompany = async (company) => {
		const collection = firebase.firestore().collection("companies");
		let details = {
			name: company.name,
			address: company.address,
			phone: company.phone,
			email: company.email,
			taxNumber: company.taxNumber,
			status: "MISSING_INVITE",
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		};
		const docRef = collection.doc();
		await docRef.set(details);

		details = await docRef.get().then(doc => {
			if (doc.exists) {
				return {
					id: doc.id,
					...doc.data(),
				}
			} else {
				return null;
			}
		})

		return { company, details };
	}

	selectLoggedInCompany = async (companyId) => {
		const collection = firebase.firestore().collection("companies");
		const doc = await collection.doc(companyId).get();

		if(doc.exists) {
			return {
				id: doc.id,
				...doc.data(),
			}
		} else {
			return null;
		}
	}

	updateCompany = async (user, companyId, data) => {
		if(user.role !== 'admin' && user.companyId !== companyId) {
			throw new Error('Access denied.');
		}

		const collection = firebase.firestore().collection("companies");
		const docRef = collection.doc(companyId);


		if(data.dayClosed === true) {
			data.dayClosed = firebase.firestore.FieldValue.serverTimestamp();
		}

		await docRef.update(data);

		return data;
	}

	resetCompany = async (user, companyId) => {
		if(user.role !== 'admin') {
			throw new Error('Access denied.');
		}

		const collection = firebase.firestore().collection("companies");
		const docRef = collection.doc(companyId);

		const data = {
			dayClosed: null,
			totalClients: 0,
			totalEvents: 0,
			totalCosts: 0,
			totalDue: 0,
			totalPaid: 0,
			totalPurchases: 0,
		}

		await docRef.update(data);

		const collections = [
			"clients",
			"costs",
			"departments",
			"events",
			"reminders",
			"serviceProviders",
			"services",
			"purchases",
			"sms",
			"stats",
			"transactions",
			"classes"
		];

		for(const collection of collections) {
			const collectionRef = docRef.collection(collection);
			const docs = await collectionRef.get();
			docs.forEach(doc => {
				collectionRef.doc(doc.id).delete();
			});
		}

		const futureDebts = firebase.firestore().collection("futureDebts");
		const fdocs = await futureDebts.where("companyId", "==", companyId).get();

		fdocs.forEach(doc => {
			futureDebts.doc(doc.id).delete();
		});

		return data;
	}

	updateLoggedInCompany = async (companyId, company) => {
		const collection = firebase.firestore().collection("companies");
		const docRef = collection.doc(companyId);

		const logo = company.preview;

		if(logo) {
			// check if the logo is an image
			if(!logo.type.startsWith('image/')) {
				throw new Error('Please upload an image file');
			}
			const extention = logo.name.split('.').pop();
			const storageRef = firebase.storage().ref();
			const fileRef = storageRef.child(`logos/${companyId}.${extention}`);
			await fileRef.put(logo);
			company.logo = await fileRef.getDownloadURL();
		}

		const {preview, previewData, ...rest} = company;
		await docRef.update(rest);
		return {...company, id: companyId};
	}

	getAuthenticatedUserDoc = async () => {
		const user = JSON.parse(sessionStorage.getItem("authUser"));

		if(!user) {
			return Promise.reject("No user found");
		}

		const collection = firebase.firestore().collection("users");
		const doc = await collection.doc(user.uid).get();


		return {
			...doc.data(),
			id: user.uid,
		}
	}


	setLoggeedInUser = user => {
		sessionStorage.setItem("authUser", JSON.stringify(user));
	};

	/**
			* Returns the authenticated user
	*/
	getAuthenticatedUser = () => {
		if (!sessionStorage.getItem("authUser")) return null;
		return JSON.parse(sessionStorage.getItem("authUser"));
	};

	/**
			* Handle the error
			* @param {*} error
	*/
	_handleError(error) {
		return error.message;
	}
}

let _fireBaseBackend = null;

/**
	* Initilize the backend
	* @param {*} config
	*/
const initFirebaseBackend = config => {
	if (!_fireBaseBackend) {
		_fireBaseBackend = new FirebaseAuthBackend(config);
	}
	return _fireBaseBackend;
};

/**
	* Returns the firebase backend
	*/
const getFirebaseBackend = () => {
	return initFirebaseBackend(firebaseConfig);
};

const db = () => {
	const app = firebase.initializeApp(firebaseConfig);
	return app.firestore();
}

export {
	initFirebaseBackend,
	getFirebaseBackend,
	db
};
