/* EVENTS */
export const GET_RECURRING_EVENTS = "GET_RECURRING_EVENTS";
export const GET_RECURRING_EVENTS_SUCCESS = "GET_RECURRING_EVENTS_SUCCESS";
export const GET_RECURRING_EVENTS_FAIL = "GET_RECURRING_EVENTS_FAIL";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAIL = "GET_EVENT_FAIL";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const GET_UPCOMMINGEVENT = "GET_UPCOMMINGEVENT";
export const GET_UPCOMMINGEVENT_SUCCESS = "GET_UPCOMMINGEVENT_SUCCESS";
export const GET_UPCOMMINGEVENT_FAIL = "GET_UPCOMMINGEVENT_FAIL";

export const ADD_GROUP_EVENT = "ADD_GROUP_EVENT";
export const ADD_GROUP_EVENT_SUCCESS = "ADD_GROUP_EVENT_SUCCESS";
export const ADD_GROUP_EVENT_FAIL = "ADD_GROUP_EVENT_FAIL";

export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const RESET_CALENDAR = "RESET_CALENDAR";
export const CANCEL_EVENT = "CANCEL_EVENT";
export const CANCEL_EVENT_FAIL = "CANCEL_EVENT_FAIL";
export const CANCEL_EVENT_SUCCESS = "CANCEL_EVENT_SUCCESS";

export const CONFIRM_EVENT = "CONFIRM_EVENT";
export const CONFIRM_EVENT_FAIL = "CONFIRM_EVENT_FAIL";
export const CONFIRM_EVENT_SUCCESS = "CONFIRM_EVENT_SUCCESS";

export const PLACEHOLDER_EVENT = "PLACEHOLDER_EVENT";
export const CLEAR_PLACEHOLDER_EVENT = "CLEAR_PLACEHOLDER_EVENT";
export const RESET_CREATE = "RESET_CREATE";

export const RESET_EVENTS = "RESET_EVENTS";
export const SET_LOADING_UPDATE_EVENT = "SET_LOADING_UPDATE_EVENT";
