import { call, put, select, takeLatest } from "redux-saga/effects";
import { getEventRemindersFromDB, finishEventReminderInDB } from "../../helpers/firebase";
import { toastr } from "../actions";
import { FINISH_EVENT_REMINDER, FINISH_EVENT_REMINDER_FAILED, FINISH_EVENT_REMINDER_SUCCESS, GET_EVENT_REMINDERS, GET_EVENT_REMINDERS_SUCCESS } from "./actionTypes";

function* getEventReminders() {
	const user = yield select((state) => state.Login.user);

	try {
		const reminders = yield call(getEventRemindersFromDB, user.companyId);

		yield put({ type: GET_EVENT_REMINDERS_SUCCESS, reminders });
	} catch (e) {
		console.log(e);
	}
}


function* finishEventReminder({id}) {
	const user = yield select((state) => state.Login.user);

	try {
		yield call(finishEventReminderInDB, user.companyId, id);

		yield put({ type: FINISH_EVENT_REMINDER_SUCCESS, id });
		yield put(toastr({
			title: "Reminder marked as completed",
			type: "success",
		}))
	} catch(e) {
		yield put({ type: FINISH_EVENT_REMINDER_FAILED, message: e.message });

		yield put(toastr({
			title: "Could not finish this reminder, please try again.",
			type: "error",
		}))
	}
}

function* eventRemindersSaga() {
	yield takeLatest(GET_EVENT_REMINDERS, getEventReminders);
	yield takeLatest(FINISH_EVENT_REMINDER, finishEventReminder);
}

export default eventRemindersSaga;
