import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const createGroupEventInDB = async (data, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const collection = await firebase.firestore().collection('companies').doc(company).collection('events');
	const docRef = collection.doc();
	await docRef.set(data);

	return await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data(),
			}
		} else {
			return null;
		}
	});
}

export const createEventInDB = async (data, user, company, fromScheduler = false) => {
	if(!company) return Promise.reject('Company is required');

	if(!fromScheduler) {
		if(user.role !== 'admin' && user.companyId !== company) {
			return Promise.reject('You are not allowed to modify this company');
		}
	}

	data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
	const {id, ...rest} = data;

	const collection = await firebase.firestore().collection('companies').doc(company).collection('events');
	const docRef = collection.doc();
	await docRef.set({...rest, cancelledAt: null});

	if(data.client) {
		const clientRef = firebase.firestore().collection('companies').doc(company).collection('clients').doc(data.client);
		const client = await clientRef.get();
		const clientData = client.data();

		let phoneNumber = clientData.phone;
		if(phoneNumber && !fromScheduler) {
			const smsRef = firebase.firestore().collection('companies').doc(company).collection('sms');
			phoneNumber = phoneNumber.replace(/\D/g,'');

			await smsRef.add({
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
				updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
				phone: phoneNumber,
				name: clientData.name,
				date: data.start,
				sp: data.serviceProviderName,
				eventId: docRef.id,
				status: 'pending',
				type: 'eventConfirmation',
			});
		}
	}

	return await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data(),
				start: data.start,
				end: data.end,
				date: data.date,
				cancelledAt: '',
			}
		} else {
			return null;
		}
		});
}

export const updateEventInDB = async (data, user, company, recalculateDebt = true) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	if(!data.id) {
		return Promise.reject('Something went wrong.');
	}

	const {id, ...rest} = data;
	const collection = await firebase.firestore().collection('companies').doc(company).collection('events').doc(id);
	const snapshot = await collection.get();

	if(recalculateDebt) {
		const currentDebt = await firebase.firestore().collection('companies')
			.doc(company)
			.collection('transactions')
			.where('eventId', '==', id)
			.where('type', '==', 'debt')
			.get();

		const debt = currentDebt.docs[0];
		if(debt) {
			const debtData = await debt.data();

			if(debtData.amount !== data.price) {
				await debt.ref.update({
					amount: data.price
				});
			}
		}
	}

	if(!snapshot.exists) {
		return Promise.reject('Event does not exist');
	} else {
		return await collection.update(rest);
	}
}

export const confirmEventInDB = async (user, company, event) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const coll = await firebase.firestore().collection('companies').doc(company).collection('events').doc(event).get();
	const eventData = coll.data();

	const client = await firebase.firestore().collection('companies').doc(company).collection('clients').doc(eventData.client).get();
	const clientData = await client.data();

	let phoneNumber = clientData.phone;

	const collection = await firebase.firestore().collection('companies').doc(company).collection('events');

	if(phoneNumber && !phoneNumber.startsWith("000")) {
		const smsRef = firebase.firestore().collection('companies').doc(company).collection('sms');
		phoneNumber = phoneNumber.replace(/\D/g,'');

		await smsRef.add({
			createdAt: firebase.firestore.FieldValue.serverTimestamp(),
			updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
			phone: phoneNumber,
			name: clientData.name,
			date: eventData.start,
			sp: eventData.serviceProviderName,
			eventId: event,
			status: 'pending',
			type: 'eventConfirmation',
		});
	}

	return await collection.doc(event).update({
		confirmedAt: firebase.firestore.FieldValue.serverTimestamp(),
		status: "Confirmed"
	});
}

export const cancelEventInDB = async (user, company, event, reason) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const collection = await firebase.firestore().collection('companies').doc(company).collection('events');

	return await collection.doc(event).update({
		cancelledAt: firebase.firestore.FieldValue.serverTimestamp(),
		reason: reason,
		});
}

export const getEventFromDB = async (user, company, id) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to access this company');
	}

	const collection = await firebase.firestore().collection('companies').doc(company).collection('events').doc(id);
	const doc = await collection.get();

	if (doc.exists) {
		return {
			id: doc.id,
			...doc.data(),
		}
	}

	return null;
}

export const getRecurringEventsFromDB = async (user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to access this company');
	}

	let list = firebase.firestore().collection('companies').doc(company).collection('events');

	if(user.role === 'user') {
		list = list.where('serviceProvider', '==', user.serviceProviderId);
	}

	const data = await list.get();

	return data.docs.map((doc) => {
		const data = {
			id: doc.id,
			...doc.data(),
		};

		['start', 'end', 'date', 'createdAt'].forEach((key) => {
			if(data[key]) {
				data[key] = new Date(data[key].seconds*1000);
			}
		})

		return data
	});
}

export const getEventsFromDB = async (user, company, filters) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to access this company');
	}

	const list = firebase.firestore().collection('companies').doc(company).collection('events');

	let query = list;

	if (filters.start && filters.end) {
		query = query.where('start', '>=', filters.start).where('start', '<=', filters.end);
	}

	query = query.where('cancelledAt', '==', null);

	if(user.role === 'user') {
		query = query.where('serviceProvider', '==', user.serviceProviderId);
	}

	const data = await query.get();

	return data.docs.map((doc) => {
		const data = {
			id: doc.id,
			...doc.data(),
		};

		['start', 'end', 'date', 'createdAt'].forEach((key) => {
			if(data[key]) {
				data[key] = new Date(data[key].seconds*1000);
			}
		})

		return data
		});
}

export const getEventsBySP = async (company, spId, filters = {from: null, to: null}) => {
	if(!company) return Promise.reject('Company is required');

	const list = firebase.firestore().collection('companies').doc(company).collection('events');

	let query = list.where('serviceProvider', '==', spId);

	if(filters.from) {
		query = query.where('start', '>=', filters.from);
	}

	if(filters.to) {
		query = query.where('start', '<', filters.to);
	}

	const data = await query.get();

	return data.docs.map((doc) => {
		const data = {
			start: doc.data().start,
			end: doc.data().end,
		};

		['start', 'end'].forEach((key) => {
			if(data[key]) {
				data[key] = new Date(data[key].seconds*1000);
			}
		})

		return data
	});
}

