import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

//import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import { Link } from 'react-router-dom';

import CostCreateModal from '../../pages/Costs/Create/modal';
import CreateTransactionModal from '../../pages/Transactions/Create/modal';

import { withTranslation } from 'react-i18next';
import { COMPANY_TYPES } from '../../globals/modules';
import { useSelector } from 'react-redux';

const WebAppsDropdown = ({t}) => {
	const type = useSelector(state => state.Company.loggedInCompany.type);

	const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
	const [addCost, setAddCost] = useState(false);
	const [transaction, setTransaction] = useState(false);
	const [support, setSupport] = useState(false);

	const toggleWebAppDropdown = () => {
		setIsWebAppDropdown(!isWebAppDropdown);
	};

	return (
		<React.Fragment>
			<Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
				<DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
					<i className='bx bx-category-alt fs-22'></i>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
					<div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
						<Row className="align-items-center">
							<Col>
								<h6 className="m-0 fw-semibold fs-15">{t("Quick Access")}</h6>
							</Col>
						</Row>
					</div>

					<div className="p-2">
						<div className="row g-0">
							<Col>
								<Link onClick={() => setAddCost(true)} className="dropdown-icon-item" to="#">
									<img src={github} alt="Github" />
									<span>{t("Cost")}</span>
								</Link>
							</Col>
							{ type === COMPANY_TYPES.DENTAL ? (
								<>
									<Col>
										<Link onClick={() => setTransaction("Product Purchase")} className="dropdown-icon-item" to="#">
											<img src={bitbucket} alt="bitbucket" />
											<span>{t("Purchase")}</span>
										</Link>
									</Col>
									<Col>
										<Link onClick={() => setTransaction("Payment")}className="dropdown-icon-item" to="#">
											<img src={dribbble} alt="dribbble" />
											<span>{t("Payment")}</span>
										</Link>
									</Col>
								</>
								) : null }
							<Col>
								<Link onClick={() => setSupport(true)}className="dropdown-icon-item" to="#">
									<img src={dribbble} alt="dribbble" />
									<span>{t("Support")}</span>
								</Link>
							</Col>
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>

			<CostCreateModal isOpen={addCost} toggle={() => setAddCost(false)} />
			<CreateTransactionModal isOpen={!!transaction} title={transaction} toggle={() => setTransaction(false)} />
		</React.Fragment>
		);
};

export default withTranslation()(WebAppsDropdown);
