import {TOAST} from "./actionTypes";

const defaultState = {
  list: []
}

const toastrReducer = (state = defaultState, action) => {
  switch (action.type) {
    case TOAST: {
      if(!action.payload) {
        return {
          ...state,
          list: []
        }
      }
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    default:
      return state;
  }
}

export default toastrReducer;
