export const GET_SERVICE_PROVIDERS = 'GET_SERVICE_PROVIDERS';

export const GET_SERVICE_PROVIDERS_SUCCESS = 'GET_SERVICE_PROVIDERS_SUCCESS';

export const GET_SERVICE_PROVIDERS_FAILURE = 'GET_SERVICE_PROVIDERS_FAILURE';

export const CREATE_SERVICE_PROVIDER = 'CREATE_SERVICE_PROVIDER';

export const CREATE_SERVICE_PROVIDER_SUCCESS = 'CREATE_SERVICE_PROVIDER_SUCCESS';

export const CREATE_SERVICE_PROVIDER_FAILURE = 'CREATE_SERVICE_PROVIDER_FAILURE';

export const UPDATE_SERVICE_PROVIDER = 'UPDATE_SERVICE_PROVIDER';

export const UPDATE_SERVICE_PROVIDER_SUCCESS = 'UPDATE_SERVICE_PROVIDER_SUCCESS';

export const UPDATE_SERVICE_PROVIDER_FAILURE = 'UPDATE_SERVICE_PROVIDER_FAILURE';

export const DELETE_SERVICE_PROVIDER = 'DELETE_SERVICE_PROVIDER';

export const DELETE_SERVICE_PROVIDER_SUCCESS = 'DELETE_SERVICE_PROVIDER_SUCCESS';

export const DELETE_SERVICE_PROVIDER_FAILURE = 'DELETE_SERVICE_PROVIDER_FAILURE';

export const UPDATE_SERVICE_PROVIDER_COLOR = 'UPDATE_SERVICE_PROVIDER_COLOR';

export const UPDATE_SERVICE_PROVIDER_COLOR_SUCCESS = 'UPDATE_SERVICE_PROVIDER_COLOR_SUCCESS';

export const UPDATE_SERVICE_PROVIDER_COLOR_FAILURE = 'UPDATE_SERVICE_PROVIDER_COLOR_FAILURE';

