import React from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import CreateCostForm from "./form";

const CreateCostsModal = ({isOpen, toggle}) => {
  return (
    <Modal backdrop={true} toggle={toggle} isOpen={isOpen} id="event-modal" centered>
      <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-info-subtle modal-title">
        Create new cost 
      </ModalHeader>
      <ModalBody>
        <CreateCostForm toggle={toggle} isModal={true} />
      </ModalBody>
    </Modal>
  )
}

export default CreateCostsModal;

