import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import CompaniesSaga from './companies/saga';
import LocationsSaga from './locations/saga';
import CalendarSaga from './calendar/saga';
import ServicesSaga from './services/saga';
import ServiceProvidersSaga from './serviceProviders/saga';
import ClientsSaga from './clients/saga';
import TransactionsSaga from './transactions/saga';
import CostsSaga from './costs/saga';
import DepartmentsSaga from './departments/saga';
import PurchasesSaga from './purchases/saga';
import SchedulerSaga from './scheduler/saga';
import ReportsSaga from './reports/saga';
import EventRemindersSaga from './eventReminders/saga';
import ClassesSaga from './classes/saga';
import GroupsSaga from './groups/saga';
import SubscriptionsSaga from './subscriptions/saga';

export default function* rootSaga() {
	yield all([
		//public
		fork(LayoutSaga),
		fork(AccountSaga),
		fork(AuthSaga),
		fork(ForgetSaga),
		fork(ProfileSaga),
		fork(CompaniesSaga),
		fork(LocationsSaga),
		fork(CalendarSaga),
		fork(ServicesSaga),
		fork(ServiceProvidersSaga),
		fork(ClientsSaga),
		fork(TransactionsSaga),
		fork(CostsSaga),
		fork(DepartmentsSaga),
		fork(PurchasesSaga),
		fork(SchedulerSaga),
		fork(ReportsSaga),
		fork(EventRemindersSaga),
		fork(ClassesSaga),
		fork(GroupsSaga),
		fork(SubscriptionsSaga),
	]);
}
