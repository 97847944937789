import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getGroupsFromDB = async (company) => {
	const collection = firebase.firestore().collection('companies').doc(company).collection('groups');

	return await collection.get().then(snapshot => {
		return snapshot.docs.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		});
	});
}

export const createGroupInDB = async (company, data) => {
	const docRef = firebase.firestore().collection('companies').doc(company).collection('groups').doc();
	data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
	data.subscriptions = 0;

	await docRef.set(data);

	return await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data()
			}
		} else {
			return null;
		}
	});
}

export const updateGroupInDB = async (company, groupId, data) => {
	const docRef = await firebase.firestore().collection('companies').doc(company).collection('groups').doc(groupId);

	await docRef.update(data);

	return await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data()
			}
		} else {
			return null;
		}
	});
}

export const assignAttendeesToGroupInDB = async (company, groupId, attendeesList) => {
	const collection = firebase.firestore().collection('companies').doc(company).collection('attendees');

	const batch = firebase.firestore().batch();

	if(!attendeesList.length) {
		const attendeesToRemove = await collection.where('groupId', '==', groupId).get();

		attendeesToRemove.forEach((doc) => {
			batch.delete(doc.ref);
		});

		return await batch.commit();
	}

	const attendeesToRemove = await collection
		.where('groupId', '==', groupId)
		.where('clientId', 'not-in', attendeesList)
		.get();

	attendeesToRemove.forEach((doc) => {
		batch.delete(doc.ref);
	});

	const now = firebase.firestore.Timestamp.now();
	attendeesList.forEach((attendee) => {
		batch.set(collection.doc(), {
			groupId: groupId,
			clientId: attendee,
			createdAt: now
		});
	});

	return await batch.commit();
}

export const deleteGroupInDB = async (company, groupId) => {
	const collection = firebase.firestore().collection('companies').doc(company).collection('groups');

	const attendeesCollection = firebase.firestore().collection('companies').doc(company).collection('attendees');

	const batch = firebase.firestore().batch();

	const attendees = await attendeesCollection.where('groupId', '==', groupId).get();

	attendees.forEach((doc) => {
		batch.delete(doc.ref);
	});

	await batch.commit();

	return await collection.doc(groupId).delete();
}

export const getGroupAttendeesFromDB = async (company, groupId) => {
	const attendeesCollection = firebase.firestore().collection('companies').doc(company).collection('attendees');
	const attendees = await attendeesCollection.where('groupId', '==', groupId).get();

	if(attendees.empty) {
		return [];
	}

	const clientsCollection = firebase.firestore().collection('companies').doc(company).collection('clients');
	// get all clients that are in attendees clientId
	const clients = await clientsCollection.where(firebase.firestore.FieldPath.documentId(), 'in', attendees.docs.map(doc => doc.data().clientId)).get();

	return clients.docs.map(doc => {
		return {
			id: doc.id,
			...doc.data()
		}
	});
}
