import { call, put, select, takeEvery } from "redux-saga/effects";
import { getSubscriptionModelsForClassFromDB } from "../../helpers/firebase/subscriptions.firebase";
import { GET_SUBSCRIPTION_MODELS_FOR_CLASS, GET_SUBSCRIPTION_MODELS_FOR_CLASS_FAILURE } from "./actionTypes";

function* getSubscriptionModelsForClass({payload}) {
	try {
		const user = yield select((state) => state.Login.user);

		const resp = yield call(getSubscriptionModelsForClassFromDB, user.company, payload)

		yield put({
			type: GET_SUBSCRIPTION_MODELS_FOR_CLASS_FAILURE,
			payload: resp
		})
	} catch(e) {
		yield put({
			type: GET_SUBSCRIPTION_MODELS_FOR_CLASS_FAILURE
		})
	}
}

export default function* serviceProvidersSaga() {
		yield takeEvery(GET_SUBSCRIPTION_MODELS_FOR_CLASS, getSubscriptionModelsForClass);
}
