import {
	CREATE_TRANSACTION, CREATE_TRANSACTION_FAILED, CREATE_TRANSACTION_SUCCESS,
	DELETE_TRANSACTION, DELETE_TRANSACTION_FAILED,
	DELETE_TRANSACTION_SUCCESS,
	GET_CLIENT_UNPAID_TRANSACTIONS,
	GET_CLIENT_UNPAID_TRANSACTIONS_FAILED,
	GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS,
	GET_TRANSACTIONS,
	GET_TRANSACTIONS_FAILED,
	GET_TRANSACTIONS_SUCCESS,
	GET_CLIENT_TRANSACTIONS,
	GET_CLIENT_TRANSACTIONS_FAILED,
	GET_CLIENT_TRANSACTIONS_SUCCESS,
	SELECT_TRANSACTION,
	SELECT_TRANSACTION_SUCCESS,
	SELECT_TRANSACTION_FAILED,
	CREATE_MANY_TRANSACTIONS,
	CREATE_MANY_TRANSACTIONS_SUCCESS,
	CREATE_MANY_TRANSACTIONS_FAILED,
    RESET_MANY_TRANSACTIONS_DATA
} from "./actionTypes";

const defaultState = {
	list: null,
	searchList: null,
	selected: null,
	loading: false,
	error: null,
	allTransactions: null,
	unpaidTransactions: null,
	finishedCreate: false,
	finishedCreateMany: false,
}

const transactionReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_TRANSACTIONS:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case GET_TRANSACTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.payload,
				finishedCreate: false,
		}
		case GET_TRANSACTIONS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload,
				finishedCreate: false,
		}
		case SELECT_TRANSACTION:
			return {
				...state,
				loading: true,
				finishedCreate: false,
				selected: null,
		}
		case SELECT_TRANSACTION_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.payload,
				finishedCreate: false,
		}
		case SELECT_TRANSACTION_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload,
				selected: null,
				finishedCreate: false,
		}
		case CREATE_TRANSACTION:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case CREATE_TRANSACTION_SUCCESS:
			return {
				...state,
				loading: false,
				list: state.list ? [action.payload, ...state.list] : [action.payload],
				finishedCreate: true,
		}
		case CREATE_TRANSACTION_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload,
				finishedCreate: false,
		}
		case DELETE_TRANSACTION:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case DELETE_TRANSACTION_SUCCESS:
			return {
				...state,
				loading: false,
				list: state.list.filter((item) => item.id !== action.payload),
				finishedCreate: false,
		}
		case DELETE_TRANSACTION_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload,
				finishedCreate: false,
		}

		case GET_CLIENT_UNPAID_TRANSACTIONS:
			return {
				...state,
				loading: true,
				error: null
		}

		case GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				unpaidTransactions: action.payload
		}

		case GET_CLIENT_UNPAID_TRANSACTIONS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
		}

		case GET_CLIENT_TRANSACTIONS:
			return {
				...state,
				loading: true,
				error: null
		}

		case GET_CLIENT_TRANSACTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				allTransactions: action.payload
		}

		case GET_CLIENT_TRANSACTIONS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
		}

		case CREATE_MANY_TRANSACTIONS:
			return {
				...state,
				loadingMany: true,
				finishedCreateMany: false,
				error: null
		}

		case CREATE_MANY_TRANSACTIONS_SUCCESS:
			return {
				...state,
				loadingMany: false,
				finishedCreateMany: true,
				error: null
		}

		case CREATE_MANY_TRANSACTIONS_FAILED:
			return {
				...state,
				loadingMany: false,
				finishedCreateMany: false,
				error: action.payload
		}

		case RESET_MANY_TRANSACTIONS_DATA:
			return {
				...state,
				loadingMany: false,
				finishedCreateMany: false,
				error: null,
		}
		default:
			return state;
	}
}


export default transactionReducer;
