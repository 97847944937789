import { APIClient } from "./api_helper";

export const getInvitationApi = async (uuid) => {
	const resp = await new APIClient().get(`/invitation/${uuid}`);
	return resp.invitation;
};

export const inviteUserApi = async (data) => {
	return await new APIClient().post("/invitation", data);
}

export const deleteInvitationApi = async (uuid) => {
	return await new APIClient().delete(`/invitation/${uuid}`);
}

