import { 
	CREATE_SCHEDULER_APPOINTMENT, 
	CREATE_SCHEDULER_APPOINTMENT_FAILED,
	CREATE_SCHEDULER_APPOINTMENT_SUCCESS,
	GET_SP_UNAVAILABLE_HOURS_SUCCESS,
	SELECT_SCHEDULE_COMPANY,
	SELECT_SCHEDULE_COMPANY_FAILED,
	SELECT_SCHEDULE_COMPANY_SUCCESS,
	DOUBLE_BOOKING,
	RESET_DOUBLE_BOOKING,
	RESET_UNAVAILABLE_HOURS
} from "./actionTypes";

const defaultState = {
	company: null,
	loading: false,
	error: false,
	finishedCreate: false,
	unavailableList: {},
	doubleBooking: false,
}

const schedulerReducer = (state = defaultState, action) => {
	switch (action.type) {
		case SELECT_SCHEDULE_COMPANY:
			return {
				...state,
				loading: true,
				error: false,
		}

		case SELECT_SCHEDULE_COMPANY_SUCCESS:
			return {
				...state,
				loading: false,
				error: false,
				company: action.payload
		}

		case SELECT_SCHEDULE_COMPANY_FAILED:
			return {
				...state,
				loading: false,
				error: true,
		}

		case CREATE_SCHEDULER_APPOINTMENT:
			return {
				...state,
				loading: true,
				error: false,
		}

		case CREATE_SCHEDULER_APPOINTMENT_SUCCESS: 
			return {
				...state,
				loading: false,
				error: false,
				finishedCreate: true,
		}

		case CREATE_SCHEDULER_APPOINTMENT_FAILED: 
			return {
				...state,
				loading: false,
				error: true,
				finishedCreate: true,
		}

		case GET_SP_UNAVAILABLE_HOURS_SUCCESS: {
			const list = action.payload.list;
			const current = state.unavailableList[action.payload.spId] || {}; 

			if(!current[action.payload.key]) {
				current[action.payload.key] = list;
			}

			return {
				...state,
				loading: false,
				error: false,
				unavailableList: {
					...state.unavailableList,
					[action.payload.spId]: current,
				}
			}
		}

		case DOUBLE_BOOKING: 
			return {
				...state,
				doubleBooking: true,
		}

		case RESET_DOUBLE_BOOKING: 
			return {
				...state,
				doubleBooking: false,
		}

		case RESET_UNAVAILABLE_HOURS:
			return {
				...state,
				unavailableList: {},
		}

		default:
			return state
	}
}

export default schedulerReducer;
