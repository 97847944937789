import {getFirebaseBackend} from "./firebase_helper";
import moment from "moment";

const firebase = getFirebaseBackend().firebase;

export const getPurchasesFromDB = async (user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to access this company');
		}

		let query = await firebase.firestore().collection('companies').doc(company).collection('purchases').orderBy('createdAt', 'desc');

		if(user.role === 'user') {
				query = await query.where('createdAt', '>=', moment().startOf('day').toDate());
				query = await query.where('createdAt', '<=', moment().endOf('day').toDate());
		}

		const list = await query.get();

		return list.docs.map((doc) => {
				return {
						id: doc.id,
						...doc.data(),
				}
		});
}

export const createPurchaseInDB = async (data, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		const collection = await firebase.firestore().collection('companies').doc(company).collection('purchases');
		const docRef = collection.doc();
		await docRef.set({
				...data,
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		});

		return await docRef.get().then(doc => {
				if (doc.exists) {
						return {
								id: doc.id,
								...doc.data(),
						}
				} else {
						return null;
				}
		});
}

export const selectPurchaseFromDB =	async (id, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}
		
		const collection = await firebase.firestore().collection('companies').doc(company).collection('purchases');
		const doc = await collection.doc(id).get();

		const transactions = await firebase.firestore().collection('companies').doc(company).collection('transactions').where('target', '==', 'purchase').where('targetId', '==', id).get();

		if (doc.exists) {
				return {
						id: doc.id,
						...doc.data(),
						payments: transactions.docs.map((doc) => {
								return {
										id: doc.id,
										...doc.data(),
								}
						}),
				}
		} else {
				return null;
		}
}

export const updatePurchaseInDB = async (id, data, originalData, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}
		
		const collection = await firebase.firestore().collection('companies').doc(company).collection('purchases');
		await collection.doc(id).update(data);

		return data;
}

export const deletePurchaseFromDB = async (id, user, company) => {
		if(!company) return Promise.reject('Company is required');
		
		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		if(!id) return Promise.reject('Purchase id is required');

		const collection = await firebase.firestore().collection('companies').doc(company).collection('purchases');
		await collection.doc(id).delete();

		return id;
}
