export const companiesMenuData = [
  {
    id: "companies",
    label: "Companies",
    icon: "ri-building-2-line",
    link: "/companies",
    subItems: [
      {
        id: "companies-list",
        label: "List",
        link: "/companies",
        parentId: "companies",
      },
      {
        id: "companies-add",
        label: "Create",
        link: "/companies/create",
        parentId: "companies",
      }
    ]
  },
];