import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { GET_INVITATION, GET_INVITATION_FAILED, GET_INVITATION_SUCCESS, REGISTER_USER } from "./actionTypes";
import { registerUserFailed } from "./actions";

import { getInvitationApi, registerUserApi } from "../../../helpers/api";
import { toastr } from "../../actions";

function* registerUser({ payload: { user, role, invitation, history } }) {
  try {
		const data = {
			name: user.name,
			phone: user.phone,
			email: user.email,
			password: user.password,
			invitationUUID: invitation.invitationUUID,
		};

		yield call(registerUserApi, data, invitation.invitationUUID);

		yield put(toastr({
			type: "success",
			title: "Registration successful. You can now log in with your credentials"
		}));

		history("/login");
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export function* getInvitation(data) {
	try {
		const response = yield call(getInvitationApi, data.payload);
    yield put({ type: GET_INVITATION_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: GET_INVITATION_FAILED, payload: error });
  }
}
function* accountSaga() {
  yield all([
    fork(watchUserRegister),
  ]);
  yield takeEvery(GET_INVITATION, getInvitation);
}

export default accountSaga;

