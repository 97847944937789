import { getFirebaseBackend } from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const selectScheduleCompanyFromDB = async (companyId) => {
		const db = firebase.firestore();
		const companiesRef = db.collection('companies');
		const company = await companiesRef.where('cid', '==', companyId).limit(1).get();

		if(company.empty) {
				return null;
		}

		let companyData = null;
		company.forEach(doc => {
				companyData = doc.data();
		});

		return {
				...companyData,
				id: company.docs[0].id
		};
}
