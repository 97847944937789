import { takeEvery, select, put, call } from 'redux-saga/effects';
import {
	CREATE_CLIENT,
	CREATE_CLIENT_FAILED, CREATE_CLIENT_SUCCESS, DELETE_CLIENT,
	DELETE_CLIENT_FAILED, DELETE_CLIENT_SUCCESS,
	GET_CLIENTS,
	GET_CLIENTS_FAILED, GET_CLIENTS_SUCCESS, SELECT_CLIENT, SELECT_CLIENT_FAILED, SELECT_CLIENT_SUCCESS, UPDATE_CLIENT, UPDATE_CLIENT_FAILED, UPDATE_CLIENT_SUCCESS
} from "./actionTypes";
import {deleteClientFromDB, selectClientFromDB, updateClientInDB} from "../../helpers/firebase";
import {toastr} from "../toastr/actions";
import { createClientApi, getClientApi, getClientsApi, updateClientApi } from '../../helpers/api/clients.api';

function* getClients() {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const resp = yield call(getClientsApi, company);

		yield put({type: GET_CLIENTS_SUCCESS, payload: resp.clients})
	} catch (e) {
		yield put({type: GET_CLIENTS_FAILED, message: e.message})
	}
}

function* createClient({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const resp = yield call(createClientApi, company, payload.data);

		yield put({type: CREATE_CLIENT_SUCCESS, payload: resp.client})
		yield put(toastr({
			title: 'Client created successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Client creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: CREATE_CLIENT_FAILED, message: e.message})
	}
}

function* selectClient({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const resp = yield call(getClientApi, company, payload.id);

		yield put({type: SELECT_CLIENT_SUCCESS, payload: resp.client})
	} catch (e) {
		yield put({type: SELECT_CLIENT_FAILED, message: e.message})
	}

}

function* updateClient({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany.ID);

		const response = yield call(updateClientApi, company, payload.id, payload.data);
		yield put({ type: UPDATE_CLIENT_SUCCESS, payload: response.client })
		yield put(toastr({
			title: 'Client updated successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Client creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: UPDATE_CLIENT_FAILED, message: e.message})
	}
}

function* deleteClient({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		yield call(deleteClientFromDB, payload.id, user, company);
		yield put({type: DELETE_CLIENT_SUCCESS, payload: payload.id})
		yield put(toastr({
			title: 'Client deleted successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put({type: DELETE_CLIENT_FAILED, message: e.message})
		yield put(toastr({
			title: 'Error deleting client, please try again.',
			type: 'danger'
		}));
	}
}

function* clientsSaga() {
	yield takeEvery(GET_CLIENTS, getClients);
	yield takeEvery(CREATE_CLIENT, createClient);
	yield takeEvery(UPDATE_CLIENT, updateClient);
	yield takeEvery(DELETE_CLIENT, deleteClient);
	yield takeEvery(SELECT_CLIENT, selectClient);
}

export default clientsSaga;
