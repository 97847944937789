import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getServicesFromDB = async (user, options, fromScheduler=false) => {
  let company = options.company;
  let search = options.search;
	let id = options.id;

	if(!company) return Promise.reject('Company is required');

	if(!fromScheduler) {
		if(!user) {
			return Promise.reject("Unauthorized");
		}
		if(user.role === 'admin') {
			if(!company) return Promise.reject('Company is required');
		}
	}

  let list = await firebase.firestore().collection('companies').doc(company).collection('services');

  if(search) {
    search = search.toLowerCase();
    list = list.where('value', '>=', search).where('value', '<=', search + '\uf8ff');
  }

	if(fromScheduler) {
		list = list.where('showOnScheduler', '==', true);
	}

  if(id) {
    list = list.doc(id);
		const doc = await list.get();
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data(),
			}
		}
  }

  const data = await list.get();

  return data.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    }
  });
}

export const createServiceInDB = async (user, company, data) => {
  if(user.role === 'admin') {
    if(!company) return Promise.reject('Company is required');
  } else {
    company = user.companyId;
  }

  data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
  data.value = data.name.toLowerCase();

  const collection = await firebase.firestore().collection('companies').doc(company).collection('services');
  const docRef = collection.doc();
  await docRef.set(data);

  return await docRef.get().then(doc => {
    if (doc.exists) {
      return {
        id: doc.id,
        ...doc.data(),
      }
    } else {
      return null;
    }
  });
}

export const updateServiceInDB = async (user, company, data) => {
  if(user.role === 'admin') {
    if(!company) return Promise.reject('Company is required');
  } else {
    company = user.companyId;
  }

  data.value = data.name.toLowerCase();

	const collection = await firebase.firestore().collection('companies').doc(company).collection('services').doc(data.id);

	const snapshot = await collection.get();
	if(!snapshot.exists) {
		return Promise.reject('Service does not exist');
	} else {
		return await collection.update(data);
	}
}

export const deleteServiceFromDB = async (user, company, id) => {
  if(user.role === 'admin') {
    if(!company) return Promise.reject('Company is required');
  } else {
    company = user.companyId;
  }

  const collection = await firebase.firestore().collection('companies').doc(company).collection('services');
  return await collection.doc(id).delete();
}
