import { APIClient } from "./api_helper";

export const getCoursesApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/courses`);
}

export const getCourseApi = async (companyId, id) => {
	return await new APIClient().get(`/company/${companyId}/courses/${id}`);
}

export const createCourseApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/courses`, data);
}

export const createAllCoursesApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/courses/full`, data);
}

export const updateCourseApi = async (companyId, courseId, data) => {
	return await new APIClient().put(`/company/${companyId}/courses/${courseId}`, data);
}

export const deleteCourseApi = async (companyId, courseId) => {
	return await new APIClient().delete(`/company/${companyId}/courses/${courseId}`);
}

