import {
  CREATE_COST, CREATE_COST_FAILED, CREATE_COST_SUCCESS,
  DELETE_COST, DELETE_COST_FAILED,
  DELETE_COST_SUCCESS,
  GET_COSTS,
  GET_COSTS_FAILED, 
  GET_COSTS_SUCCESS
} from "./actionTypes";

const defaultState = {
  list: null,
  searchList: null,
  loading: false,
  error: null,
  finishedCreate: false,
}

const costsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_COSTS:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case GET_COSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        finishedCreate: false,
      }
    case GET_COSTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case CREATE_COST:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case CREATE_COST_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: state.list ? [action.payload, ...state.list] : [action.payload],
        finishedCreate: true,
      }
		}
    case CREATE_COST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case DELETE_COST:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case DELETE_COST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((item) => item.id !== action.payload),
        finishedCreate: false,
      }
    case DELETE_COST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    default:
      return state;
  }
}


export default costsReducer;
