import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getClientsFromDB = async (user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to access this company');
	}

	const list = await firebase.firestore().collection('companies').doc(company).collection('clients').get();

	return list.docs.map((doc) => {
		return {
			id: doc.id,
			...doc.data(),
		}
		});
}

export const getClientByPhone = async (phone, company) => {
	if(!company) return Promise.reject('Company is required');

	const list = await firebase.firestore()
		.collection('companies')
		.doc(company)
		.collection('clients')
		.where('phone', '==', phone)
		.limit(1)
		.get()

	if(list.empty) {
		return null;
	}

	const client = list.docs[0];
	return {
		id: client.id,
		...client.data(),
	}
}

export const createClientInDB = async (data, user, company, fromScheduler = false) => {
	if(!company) return Promise.reject('Company is required');

	if(!fromScheduler) {
		if(user.role !== 'admin' && user.companyId !== company) {
			return Promise.reject('You are not allowed to modify this company');
		}
	}

	data.createdAt = firebase.firestore.FieldValue.serverTimestamp();

	const collection = await firebase.firestore().collection('companies').doc(company).collection('clients');
	const docRef = collection.doc();
	await docRef.set(data);

	const companyRef = await firebase.firestore().collection('companies').doc(company);
	await companyRef.update({
		totalClients: firebase.firestore.FieldValue.increment(1),
	});

	return await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data(),
			}
		} else {
			return null;
		}
		});
}

export const selectClientFromDB = async (id, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const collection = await firebase.firestore().collection('companies').doc(company).collection('clients').doc(id);
	const doc = await collection.get();

	if (doc.exists) {
		return {
			id: doc.id,
			...doc.data(),
		}
	} else {
		return null;
	}
}

export const updateClientInDB = async (data, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const collection = await firebase.firestore().collection('companies').doc(company).collection('clients').doc(data.id);
	const snapshot = await collection.get();

	if(!snapshot.exists) {
		return Promise.reject('Client does not exist');
	} else {
		return await collection.update(data);
	}
}

export const deleteClientFromDB = async (id, user, company) => {
	if(!company) return Promise.reject('Company is required');

	if(user.role !== 'admin' && user.companyId !== company) {
		return Promise.reject('You are not allowed to modify this company');
	}

	const collection = await firebase.firestore().collection('companies').doc(user.companyId).collection('clients');
	const docRef = collection.doc(id);
	await docRef.delete();

	return true;
}
