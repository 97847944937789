import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import {
	GET_COMPANIES,
	CREATE_COMPANY,
	GET_COMPANIES_SUCCESS,
	GET_COMPANIES_FAILED,
	CREATE_COMPANY_SUCCESS,
	CREATE_COMPANY_FAILED,
	CREATE_COMPANY_USER,
	CREATE_COMPANY_USER_SUCCESS,
	CREATE_COMPANY_USER_FAILED,
	SELECT_LOGGED_IN_COMPANY_FAILED,
	SELECT_LOGGED_IN_COMPANY,
	SELECT_LOGGED_IN_COMPANY_SUCCESS,
	UPDATE_LOGGED_IN_COMPANY,
	UPDATE_LOGGED_IN_COMPANY_SUCCESS,
	UPDATE_LOGGED_IN_COMPANY_FAILED,
	SELECT_COMPANY,
	SELECT_COMPANY_SUCCESS,
	SELECT_COMPANY_FAILED,
	UPDATE_COMPANY,
	UPDATE_COMPANY_FAILED,
	UPDATE_COMPANY_SUCCESS,
	CLOSE_DAY,
	CLOSE_DAY_FAILED,
	RESET_COMPANY
} from "./actionTypes";
import { getFirebaseBackend } from "../../helpers/firebase/firebase_helper";
import { toastr } from '../actions';
import { createCompanyApi, getCompaniesApi, updateCompanyApi } from '../../helpers/api';


const fireBaseBackend = getFirebaseBackend();
function* getCompanies() {
	try {
		const response = yield call(getCompaniesApi);

		yield put({ type: GET_COMPANIES_SUCCESS, payload: response.companies });
	}catch (error) {
		yield put({ type: GET_COMPANIES_FAILED, payload: error });
	}
}

function* selectCompany({ payload }) {
	try {
		const id = payload;

		const company = yield call(fireBaseBackend.selectCompany, id);

		yield put({ type: SELECT_COMPANY_SUCCESS, payload: company });
	}catch (error) {
		yield put({ type: SELECT_COMPANY_FAILED, payload: error });
	}
}

function* createCompany(data) {
	try {
		const response = yield call(createCompanyApi, data.payload);
		yield put({ type: CREATE_COMPANY_SUCCESS, payload: response });
	} catch (error) {
		yield put({ type: CREATE_COMPANY_FAILED, payload: error });
	}
}

function* createCompanyUser(data) {
	try {
		const user = data.payload;

		yield call(fireBaseBackend.inviteUser, user, "company");
		yield put({ type: CREATE_COMPANY_USER_SUCCESS })
	} catch(e) {
		yield put({ type: CREATE_COMPANY_USER_FAILED, payload: e })
	}
}

function* selectLoggedInCompany(payload) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;

		if(!company) {
			company = user.companyId;
		}

		const response = yield call(fireBaseBackend.selectLoggedInCompany, company);
		yield put({ type: SELECT_LOGGED_IN_COMPANY_SUCCESS, payload: response });
	} catch (error) {
		yield put({ type: SELECT_LOGGED_IN_COMPANY_FAILED, payload: error });
	}
}

function* updateLoggedInCompany({payload}) {
	try {
		const companyId = yield select(state => state.Company.loggedInCompany.ID);

		const response = yield call(updateCompanyApi, {ID: companyId, ...payload.data});
		let user = localStorage.getItem('authUser');
		user = JSON.parse(user);

		user.company = response.company;
		localStorage.setItem('authUser', JSON.stringify(user));

		yield put({ type: UPDATE_LOGGED_IN_COMPANY_SUCCESS, payload: response.company });

		if(payload.toast === false) {
			return;
		}

		yield put(toastr({
			title: "Company updated successfully",
			type: 'success'
		}));
	} catch (error) {
		yield put({ type: UPDATE_LOGGED_IN_COMPANY_FAILED, payload: error });

		let message = error.message;
		if(!message.includes('no permission to update') && !message.includes('upload an image')) {
			message = "Company update failed, please try again.";
		}
		yield put(toastr({
			title: message,
			type: 'error'
		}));
	}
}

function* updateCompany({payload}) {
	try {
		const user = yield select(state => state.Login.user);

		let company = payload.id;

		if(!company) {
			company = user.companyId;
		}

		yield call(updateCompanyApi, payload);


		yield put({ type: UPDATE_COMPANY_SUCCESS, payload });

		if(payload.toast === false) {
			return;
		}

		yield put(toastr({
			title: "Company updated successfully",
			type: 'success'
		}));
	} catch(e) {
		yield put({ type: UPDATE_COMPANY_FAILED, message: e });
	}
}

function* closeDay() {
	try {
		const user = yield select(state => state.Login.user);
		const company = user.companyId;

		yield call(fireBaseBackend.updateCompany, user, company, { dayClosed: true });

		yield put(toastr({
			title: "Company updated successfully",
			type: 'success'
		}));

		window.location.reload();
	} catch(e) {
		yield put({ type: CLOSE_DAY_FAILED, message: e });
		yield put(toastr({
			title: "Failed to close day, please try again.",
			type: 'error'
		}));
	}
}

function* resetCompany({payload}) {
	try {
		const user = yield select(state => state.Login.user);

		yield call(fireBaseBackend.resetCompany, user, payload);

		yield put(toastr({
			title: "All data for this company has been reset",
			type: 'success'
		}));
	} catch(e) {
		yield put({ type: CLOSE_DAY_FAILED, message: e });
		yield put(toastr({
			title: "Failed to reset the company, please try again.",
			type: 'error'
		}));
	}
}

function* companiesSaga() {
	yield takeEvery(CREATE_COMPANY, createCompany);
	yield takeEvery(SELECT_COMPANY, selectCompany);
	yield takeLatest(GET_COMPANIES, getCompanies);
	yield takeEvery(CREATE_COMPANY_USER, createCompanyUser);
	yield takeEvery(SELECT_LOGGED_IN_COMPANY, selectLoggedInCompany);
	yield takeEvery(UPDATE_LOGGED_IN_COMPANY, updateLoggedInCompany);
	yield takeLatest(UPDATE_COMPANY, updateCompany);
	yield takeLatest(CLOSE_DAY, closeDay);
	yield takeLatest(RESET_COMPANY, resetCompany);
}

export default companiesSaga;
