import React from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import { withTranslation } from 'react-i18next';
import CreateTransactionForm from "./form";

const CreateCostsModal = ({target = '', targetId = '', isOpen, toggle, title, client = null, t, max=null}) => {
  return (
    <Modal backdrop={true} toggle={toggle} isOpen={isOpen} id="event-modal" centered>
      <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-info-subtle modal-title">
								{t(`Add new ${title}`)} 
      </ModalHeader>
      <ModalBody>
        <CreateTransactionForm 
										fixedType={title ? title.toLowerCase() : null} 
										toggle={toggle} 
										isModal={true} 
										max={max}
										client={client}
										target={target}
										targetId={targetId}
								/>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(CreateCostsModal);

