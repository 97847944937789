import {
	takeEvery,
	takeLatest,
	select,
	put,
	call,
} from 'redux-saga/effects';

import {
	GET_TRANSACTIONS,
	GET_TRANSACTIONS_FAILED,
	GET_TRANSACTIONS_SUCCESS,
	CREATE_TRANSACTION,
	DELETE_TRANSACTION,
	CREATE_TRANSACTION_FAILED,
	CREATE_TRANSACTION_SUCCESS,
	GET_CLIENT_UNPAID_TRANSACTIONS,
	GET_CLIENT_UNPAID_TRANSACTIONS_FAILED,
	GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS,
	GET_CLIENT_TRANSACTIONS,
	GET_CLIENT_TRANSACTIONS_SUCCESS,
	GET_CLIENT_TRANSACTIONS_FAILED,
	SELECT_TRANSACTION,
	SELECT_TRANSACTION_FAILED,
	SELECT_TRANSACTION_SUCCESS,
    CREATE_MANY_TRANSACTIONS,
    CREATE_MANY_TRANSACTIONS_FAILED,
    CREATE_MANY_TRANSACTIONS_SUCCESS
} from './actionTypes';

import {
	createTransactionInDB,
	getClientUnpaidTransactionsFromDB,
	getTransactionsFromDB,
	getClientTransactionsFromDB,
	selectTransactionFromDB
} from '../../helpers/firebase';
import {toastr} from "../toastr/actions";
import { SELECT_CLIENT } from '../clients/actionTypes';
import { updateEventInDB } from '../../helpers/firebase/events.firebase';
import { SET_LOADING_UPDATE_EVENT, UPDATE_EVENT, UPDATE_EVENT_SUCCESS } from '../calendar/actionTypes';
import { updateEvent } from '../actions';

function* getTransactions({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;

		if(!company) {
			company = user.companyId;
		}

		const transactions = yield select(state => state.Transactions.list);
		if(!transactions) {
			const response = yield call(getTransactionsFromDB, user, company);
			yield put({type: GET_TRANSACTIONS_SUCCESS, payload: response})
		} else {
			yield put({type: GET_TRANSACTIONS_SUCCESS, payload: transactions});
		}
	} catch (e) {
		yield put({type: GET_TRANSACTIONS_FAILED, message: e.message})
	}
}

function* createTransaction({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		const selectedClient = yield select(state => state.Clients.selected);
		const response = yield call(createTransactionInDB, payload.data, user, company);

		if(selectedClient) {
			yield put({type: SELECT_CLIENT, payload: { id: selectedClient.id} })
			yield put({type: GET_CLIENT_UNPAID_TRANSACTIONS, payload: {clientId: selectedClient.id, company: company}})
			yield put({type: GET_CLIENT_TRANSACTIONS, payload: {clientId: selectedClient.id, company: company}})
		}

		yield put({type: CREATE_TRANSACTION_SUCCESS, payload: response.data})
		yield put(toastr({
			title: 'Transaction created successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Transaction creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: CREATE_TRANSACTION_FAILED, message: e.message})
	}
}

function* deleteTransaction() {
	yield console.log('deleteTransaction');
}

function* getClientUnpaidTransactions({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		if(!payload?.clientId) {
			yield put({type: GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS, payload: null})
		} else {
			const response = yield call(getClientUnpaidTransactionsFromDB, payload.clientId, user, company);

			yield put({type: GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS, payload: response})
		}
	} catch (e) {
		yield put({type: GET_CLIENT_UNPAID_TRANSACTIONS_FAILED, message: e.message})
		yield put(toastr({
			title: 'Failed to get client unpaid transactions',
			type: 'error'
		}));
	}
}

function* getClientTransactions({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		const response = yield call(getClientTransactionsFromDB, payload.clientId, user, company);

		yield put({type: GET_CLIENT_TRANSACTIONS_SUCCESS, payload: response})
	} catch (e) {
		yield put({type: GET_CLIENT_TRANSACTIONS_FAILED, message: e.message})
		yield put(toastr({
			title: 'Failed to get client transactions',
			type: 'error'
		}));
	}
}

function* selectTransaction({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		const response = yield call(selectTransactionFromDB, payload.id, user, company);

		yield put({type: SELECT_TRANSACTION_SUCCESS, payload: response})
	} catch (e) {
		yield put({type: SELECT_TRANSACTION_FAILED, message: e.message})
		yield put(toastr({
			title: 'Failed to get transaction details',
			type: 'error'
		}));
	}
}

function* createManyTransactions({payload}) {
	try {
		const { transactions, event } = payload;
		let user = yield select(state => state.Login.user);
		const company = user.companyId;

		const payments = transactions.filter(t => t.type === 'payment');
		const debts = transactions.filter(t => t.type === 'debt');

		let totalPaid = 0;

		for(const payment of payments) {
			yield call(createTransactionInDB, payment, user, company);

			totalPaid += parseInt(payment.amount);
		}

		for(const debt of debts) {
			yield call(createTransactionInDB, debt, user, company);
		}

		const eventDataToUpdate = {
			id: event.id,
			paidAmount: totalPaid,
			paid: totalPaid >= event.price,
			paymentAdded: true
		}

		yield put({type: SET_LOADING_UPDATE_EVENT});

		yield call(updateEventInDB, eventDataToUpdate, user, company, false);

		yield put({type: UPDATE_EVENT_SUCCESS, payload: eventDataToUpdate});

		yield put({type: CREATE_MANY_TRANSACTIONS_SUCCESS})
		yield put(toastr({
			title: 'Successfully added payment to event',
			type: 'success'
		}));
	} catch (e) {
		console.log(e);
		yield put({type: CREATE_MANY_TRANSACTIONS_FAILED, message: e.message})
		yield put(toastr({
			title: 'Failed to create transactions',
			type: 'error'
		}));
	}
}

function* transactionsSaga() {
	yield takeEvery(SELECT_TRANSACTION, selectTransaction);
	yield takeLatest(GET_TRANSACTIONS, getTransactions);
	yield takeEvery(CREATE_TRANSACTION, createTransaction);
	yield takeLatest(DELETE_TRANSACTION, deleteTransaction);
	yield takeLatest(GET_CLIENT_UNPAID_TRANSACTIONS, getClientUnpaidTransactions);
	yield takeLatest(GET_CLIENT_TRANSACTIONS, getClientTransactions);
	yield takeLatest(CREATE_MANY_TRANSACTIONS, createManyTransactions);
}

export default transactionsSaga;

