import { APIClient } from "./api_helper";

export const getDepartmentsApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/departments`);
}

export const createDepartmentApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/departments`, data);
}

export const updateDepartmentApi = async (companyId, departmentId, data) => {
	return await new APIClient().put(`/company/${companyId}/departments/${departmentId}`, data);
}

export const deleteDepartmentApi = async (companyId, departmentId) => {
	return await new APIClient().delete(`/company/${companyId}/departments/${departmentId}`);
}

