export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

export const ASSIGN_ATTENDEES_TO_GROUP = 'ASSIGN_ATTENDEES_TO_GROUP';
export const ASSIGN_ATTENDEES_TO_GROUP_SUCCESS = 'ASSIGN_ATTENDEES_TO_GROUP_SUCCESS';
export const ASSIGN_ATTENDEES_TO_GROUP_FAILURE = 'ASSIGN_ATTENDEES_TO_GROUP_FAILURE';

export const GET_GROUP_ATTENDEES = 'GET_GROUP_ATTENDEES';
export const GET_GROUP_ATTENDEES_SUCCESS = 'GET_GROUP_ATTENDEES_SUCCESS';
export const GET_GROUP_ATTENDEES_FAILURE = 'GET_GROUP_ATTENDEES_FAILURE';

