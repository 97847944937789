import { addDays, addMonths } from "date-fns";
import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const createClientReminder = async (_user, company, event, client) => {
	if(!company) return Promise.reject('Company is required');

	let reminderData = null;

	for(let i = 0; i < event.services.length; i++) {
		const service = event.services[i];
		const reminder = service.service.reminder;

		// doesn't exist, or is empty
		if(reminder === null || reminder === undefined) { 
			continue;
		}

		// object is empty
		if(Object.keys(reminder).length === 0) {
			continue;
		}

		// Object is not empty, but reminder is set to 0
		if(parseInt(reminder.amount, 10) === 0) {
			continue;
		}

		let reminderDate = null;

		if(reminder.type === 'days') {
			reminderDate = addDays(new Date(), parseInt(reminder.amount, 10));
		} else {
			reminderDate = addMonths(new Date(), parseInt(reminder.amount, 10));
		}

		reminderData = {
			eventId: event.id,
			eventDate: firebase.firestore.Timestamp.fromDate(event.start),
			clientId: event.client,
			clientName: event.clientName,
			clientPhone: client.phone,
			createdAt: firebase.firestore.Timestamp.now(),
			serviceId: service.service.value,
			serviceName: service.service.label,
			reminderAt: firebase.firestore.Timestamp.fromDate(reminderDate),
			finishedAt: null,
		}

		break;
	}

	if (reminderData === null) {
		return Promise.resolve(true);
	}

	const rmdRef = firebase.firestore().collection("companies").doc(company).collection('reminders');

	return rmdRef.add(reminderData)
		.then(_docRef => {
			return true;
		})
		.catch(error => {
			return Promise.reject(error);
		});
}


export const getEventRemindersFromDB = async (company) => {
	if(!company) return Promise.reject('Company is required');

	const remindersRef = firebase.firestore().collection("companies").doc(company).collection('reminders');

	const reminders = await remindersRef
		.where("reminderAt", "<=", firebase.firestore.Timestamp.fromDate(addDays(new Date(), 3)))
		.where("finishedAt", "==", null)
		.orderBy("reminderAt", "asc")
		.get();

	return reminders.docs.map(doc => {
		return {id: doc.id, ...doc.data()}
	});
}

export const finishEventReminderInDB = async (company, id) => {
	if(!company) return Promise.reject('Company is required');
	
	const reminderRef = firebase.firestore().collection("companies").doc(company).collection('reminders').doc(id);

	return reminderRef.update({
		finishedAt: firebase.firestore.Timestamp.now()
	})
		.then(() => {
			return true;
		})
		.catch(error => {
			return Promise.reject(error);
		});
}
