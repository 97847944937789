import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const createFutureDebt = async (data) => {
	const collection = firebase.firestore().collection('futureDebts');
	const docRef = collection.doc();

	await docRef.set(data)

	return await docRef.get().then(doc => {
		if (doc.exists) {
			return {
				id: doc.id,
				...doc.data()
			}
		} else {
			return null;
		}
	});
}
