export const GET_ALL_CLASSES = 'GET_ALL_CLASSES';
export const GET_ALL_CLASSES_SUCCESS = 'GET_ALL_CLASSES_SUCCESS';
export const GET_ALL_CLASSES_FAILED = 'GET_ALL_CLASSES_FAILED';

export const GET_CLASS = 'GET_CLASS';
export const GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS';
export const GET_CLASS_FAILED = 'GET_CLASS_FAILED';

export const CREATE_CLASS = 'CREATE_CLASS';
export const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
export const CREATE_CLASS_FAILED = 'CREATE_CLASS_FAILED';

export const UPDATE_CLASS = 'UPDATE_CLASS';
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
export const UPDATE_CLASS_FAILED = 'UPDATE_CLASS_FAILED';

export const DELETE_CLASS = 'DELETE_CLASS';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAILED = 'DELETE_CLASS_FAILED';

