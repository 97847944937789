import {
	takeEvery,
	takeLatest,
	select,
	put,
	call,
} from 'redux-saga/effects';

import {
	GET_DEPARTMENTS,
	GET_DEPARTMENTS_FAILED,
	GET_DEPARTMENTS_SUCCESS,
	CREATE_DEPARTMENT,
	DELETE_DEPARTMENT,
	CREATE_DEPARTMENT_FAILED,
	CREATE_DEPARTMENT_SUCCESS,
	DELETE_DEPARTMENT_SUCCESS,
	DELETE_DEPARTMENT_FAILED,
	UPDATE_DEPARTMENT,
	UPDATE_DEPARTMENT_FAILED,
	UPDATE_DEPARTMENT_SUCCESS
} from './actionTypes';

import { updateDepartmentInDB, deleteDepartmentFromDB } from '../../helpers/firebase';
import {toastr} from "../toastr/actions";
import { getServices } from '../actions';
import { createDepartmentApi, deleteDepartmentApi, getDepartmentsApi, updateDepartmentApi } from '../../helpers/api/departments.api';

function* getDepartments() {
	try {
		let company = yield select(state => state.Company.loggedInCompany);

		const resp = yield call(getDepartmentsApi, company.ID);
		yield put({type: GET_DEPARTMENTS_SUCCESS, payload: resp.departments})
	} catch (e) {
		yield put({type: GET_DEPARTMENTS_FAILED, message: e.message})
	}
}

function* createDepartment({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany);

		const resp = yield call(createDepartmentApi, company.ID, payload.data);

		yield put({type: CREATE_DEPARTMENT_SUCCESS, payload: resp.department})
		yield put(getServices({force: true}));
		yield put(toastr({
			title: 'Department created successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Department creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: CREATE_DEPARTMENT_FAILED, message: e.message})
	}
}

function* updateDepartment({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany);

		const resp = yield call(updateDepartmentApi, company.ID, payload.id, payload.data);
		//const response = yield call(updateDepartmentInDB, payload.id, payload.data, payload.originalData, user, company);

		yield put({type: UPDATE_DEPARTMENT_SUCCESS, payload: resp.department})
		yield put(getServices({force: true}));
		yield put(toastr({
			title: 'Department created successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Department creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: UPDATE_DEPARTMENT_FAILED, message: e.message})
	}
}

function* deleteDepartment({payload}) {
	try {
		let company = yield select(state => state.Company.loggedInCompany);

		yield call(deleteDepartmentApi, company.ID, payload.id);
		yield put({type: DELETE_DEPARTMENT_SUCCESS, payload: payload.id})
		yield put(toastr({
			title: 'Department deleted successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put({type: DELETE_DEPARTMENT_FAILED, message: e.message})
		yield put(toastr({
			title: 'Error deleting department, please try again.',
			type: 'danger'
		}));
	}
}

function* departmentsSaga() {
	yield takeLatest(GET_DEPARTMENTS, getDepartments);
	yield takeEvery(CREATE_DEPARTMENT, createDepartment);
	yield takeEvery(UPDATE_DEPARTMENT, updateDepartment);
	yield takeLatest(DELETE_DEPARTMENT, deleteDepartment);
}

export default departmentsSaga;

