import { APIClient } from "./api_helper";

export const getClientsApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/clients`);
}

export const searchClientsApi = async (companyId, query) => {
	return await new APIClient().get(`/company/${companyId}/clients/search`, {query});
}

export const getClientApi = async (companyId, id) => {
	return await new APIClient().get(`/company/${companyId}/clients/${id}`);
}

export const createClientApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/clients`, data);
}

export const updateClientApi = async (companyId, id, data) => {
	return await new APIClient().put(`/company/${companyId}/clients/${id}`, data);
}

export const getClientAttendanceOfGroupApi = async (companyId, clientId, groupId) => {
	return await new APIClient().get(`/company/${companyId}/clients/${clientId}/attendance/${groupId}`);
}

export const getClientPaymentsApi = async (companyId, clientId) => {
	return await new APIClient().get(`/company/${companyId}/clients/${clientId}/payments`);
}

export const getClientNotesApi = async (companyId, clientId) => {
	return await new APIClient().get(`/company/${companyId}/clients/${clientId}/notes`);
}

export const createClientNoteApi = async (companyId, clientId, data) => {
	return await new APIClient().post(`/company/${companyId}/clients/${clientId}/notes`, data);
}

export const deleteClientNoteApi = async (companyId, clientId, noteId) => {
	return await new APIClient().delete(`/company/${companyId}/clients/${clientId}/notes/${noteId}`);
}

export const deleteClientApi = async (companyId, id) => {
	return await new APIClient().delete(`/company/${companyId}/clients/${id}`);
}
