import {CLEAR_CLIENTS, CREATE_CLIENT, DELETE_CLIENT, GET_CLIENTS, UPDATE_CLIENT, SELECT_CLIENT, DELETE_CLIENT_SUCCESS} from "./actionTypes";

export const getClients = (company = '') => ({
  type: GET_CLIENTS,
  payload: {company}
});

export const clearClients = () => ({
  type: CLEAR_CLIENTS,
});

export const createClient = (data, company = '') => ({
  type: CREATE_CLIENT,
  payload: {data, company}
});

export const updateClient = (id, data, company = '') => ({
  type: UPDATE_CLIENT,
  payload: {id, data, company}
});

export const deleteClient = (id, company = '') => ({
  type: DELETE_CLIENT,
  payload: {id, company}
});

export const deleteClientSuccess = (id) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: id
});

export const selectClient = (id, company = '') => ({
		type: SELECT_CLIENT,
		payload: {
				id,
				company,
		},
});
