import { APIClient } from "./api_helper";

export const getCostsApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/costs`);
}

export const getCostApi = async (companyId, id) => {
	return await new APIClient().get(`/company/${companyId}/costs/${id}`);
}

export const createCostApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/costs`, data);
}

export const updateCostApi = async (companyId, costId, data) => {
	return await new APIClient().put(`/company/${companyId}/costs/${costId}`, data);
}

export const deleteCostApi = async (companyId, costId) => {
	return await new APIClient().delete(`/company/${companyId}/costs/${costId}`);
}

