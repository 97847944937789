import { addDays, endOfDay, format, startOfDay } from "date-fns";
import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getReportFromDB = async (from, to, user, company) => {
	const snapshot = await firebase.firestore().collection("companies").doc(company).collection('stats')
		.where('date', '>=', from)
		.where('date', '<', to)
		.get();

	const data = [];

	snapshot.forEach(doc => {
		const docData = doc.data();

		const date = startOfDay(docData.date.toDate());

		data.push({
			due: docData.totalDue ?? 0,
			paid: docData.totalPaid ?? 0,
			costs: docData.costs ?? 0,
			purchases: docData.purchaseCount ?? 0,
			date: date,
		});
	});

	while(from < to) {
		//eslint-disable-next-line
		if(data.find(d => format(d.date.getTime(), 'yyyy-MM-dd') === format(from.getTime(), 'yyyy-MM-dd'))) {
			from = addDays(from, 1);
			continue;
		}

		data.push({
			due: 0,
			paid: 0,
			revenue: 0,
			costs: 0,
			purchases: 0,
			date: from,
		});

		from = addDays(from, 1);
	}

	data.sort((a, b) => a.date - b.date);

	return data
}

export const getDailyReportFromDB = async (user, company, date) => {
	const from = startOfDay(date);
	const to = endOfDay(date);

	// GET COSTS DAILY REPORT
	const costs = await firebase.firestore().collection("companies").doc(company).collection('costs')
		.where('createdAt', '>=', from)
		.where('createdAt', '<=', to)
		.get();

	const costsData = costs.docs.map(doc => doc.data());

	const totalCosts = costsData.reduce((acc, cost) => acc + parseInt(cost.amount, 10), 0);
	// END COST DAILY REPORT

	// GET TRANSACTIONS DAILY REPORT
	const transactions = await firebase.firestore().collection("companies").doc(company).collection('transactions')
		.where('createdAt', '>=', from)
		.where('createdAt', '<=', to)
		.get();

	const transactionsData = transactions.docs.map(doc => doc.data());

	let totalDue = 0;
	let totalPaid = 0;

	transactionsData.forEach(transaction => {
		if(transaction.type === 'debt' && !transaction.paid) {
			totalDue += transaction.amount;
		} 

		if(transaction.type === 'payment') {
			totalPaid += transaction.amount;
		}
	});

	// END TRANSACTIONS DAILY REPORT

	// GET PURCHASES DAILY REPORT
	const purchases = await firebase.firestore().collection("companies").doc(company).collection('purchases')
		.where('createdAt', '>=', from)
		.where('createdAt', '<=', to)
		.get();
	
	const purchasesData = purchases.docs.map(doc => doc.data());

	const purchaseRevenue = purchasesData.reduce((acc, purchase) => acc + purchase.price, 0);

	const products = {};

	for(let i = 0; i < purchasesData.length; i++) {
		for(let j = 0; j < purchasesData[i].services.length; j++) {
			const product = purchasesData[i].services[j];
			const amount = product.amount;
			const details = product.service;

			if(products[details.label]) {
				products[details.label] += amount
			} else {
				products[details.label] = amount
			}
		}
	}

	// EVENTS DAILY REPORT START
	const events = await firebase.firestore().collection("companies").doc(company).collection('events')
		.where('end', '>=', from)
		.where('end', '<=', to)
		.get()
	// EVENTS DAILY REPORT END


	return {
		costs: totalCosts,
		costsList: costsData,
		transactions: transactionsData,
		totalDue,
		totalPaid,
		products,
		purchaseRevenue,
		purchases: purchasesData,
		events: events.docs.length
	}
}
