import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  CREATE_LOCATION, CREATE_LOCATION_FAILURE,
  CREATE_LOCATION_SUCCESS, DELETE_LOCATION, DELETE_LOCATION_FAILURE, DELETE_LOCATION_SUCCESS,
  GET_LOCATIONS,
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_SUCCESS,
	UPDATE_LOCATION, UPDATE_LOCATION_FAILURE, UPDATE_LOCATION_SUCCESS
} from "./actionTypes";
import {getLocationsFromDB, createLocationInDB, updateLocationInDB, deleteLocationFromDB} from "../../helpers/firebase";
import {toastr} from "../toastr/actions";

function* getLocations({payload}) {
  try {
    let company = payload;
		let locations = yield select(state => state.Locations.list);
		if(!locations?.length) {
      let user = yield select(state => state.Login.user);
      if(!company) {
        company = user.companyId;
      }
      const response = yield call(getLocationsFromDB, user, company);
      yield put({ type: GET_LOCATIONS_SUCCESS, payload: response });
	} else {
      yield put({ type: GET_LOCATIONS_SUCCESS, payload: locations });
	}
  } catch (error) {
    yield put({ type: GET_LOCATIONS_FAILURE, payload: error })
  }
}

function* createLocation(data) {
  try {
    let user = yield select(state => state.Login.user);
    const response = yield call(createLocationInDB, data.payload, user);
    yield put({ type: CREATE_LOCATION_SUCCESS, payload: response });
    yield put(toastr({
      type: 'success',
      title: 'Successfully created location',
    }));
  } catch (error) {
    yield put(toastr({
      type: 'danger',
      title: 'Error creating location',
    }));
    yield put({ type: CREATE_LOCATION_FAILURE, payload: error });
  }
}

function* updateLocation({payload}) {
  try {
    let company = payload.company;
    let user = yield select(state => state.Login.user);

    if(!company) {
      company = user.companyId;
    }

		const locationData = {...payload.location, id: payload.id};
    const response = yield call(updateLocationInDB, locationData, user, company);
    yield put({ type: UPDATE_LOCATION_SUCCESS, payload: locationData });
    yield put(toastr({
      type: 'success',
      title: 'Successfully created location',
    }));
  } catch (error) {
    yield put(toastr({
      type: 'danger',
      title: 'Error creating location',
    }));
    yield put({ type: UPDATE_LOCATION_FAILURE, payload: error });
  }
}

function* deleteLocation({payload}) {
  try {
    let user = yield select(state => state.Login.user);

    yield call(deleteLocationFromDB, payload, user);
    yield put({ type: DELETE_LOCATION_SUCCESS, payload })
    yield put(toastr({
      type: 'success',
      title: 'Successfully deleted location',
    }));
  } catch (e) {
    yield put(toastr({
      type: 'danger',
      title: 'Error deleting this location',
    }));
    yield put({ type: DELETE_LOCATION_FAILURE, payload: e })
  }
}

function* locationsSaga() {
  yield takeLatest(GET_LOCATIONS, getLocations);
  yield takeLatest(CREATE_LOCATION, createLocation);
  yield takeLatest(UPDATE_LOCATION, updateLocation);
  yield takeLatest(DELETE_LOCATION, deleteLocation);
}

export default locationsSaga;
