import { APIClient } from "./api_helper"

export const getServiceProvidersApi = async (companyId) => {
	return await new APIClient().get(`company/${companyId}/service-providers`)
}

export const createServiceProviderApi = async (companyId, data) => {
	return await new APIClient().post(`company/${companyId}/service-providers`, data)
}

export const selectServiceProviderApi = async (companyId, serviceProviderId) => {
	return await new APIClient().get(`company/${companyId}/service-providers/${serviceProviderId}`)
}

export const updateServiceProviderApi = async (companyId, serviceProviderId, data) => {
	return await new APIClient().put(`company/${companyId}/service-providers/${serviceProviderId}`, data)
}

export const deleteServiceProviderApi = async (companyId, serviceProviderId) => {
	return await new APIClient().delete(`company/${companyId}/service-providers/${serviceProviderId}`)
}
