import {getFirebaseBackend} from "./firebase_helper";
import moment from "moment";

const firebase = getFirebaseBackend().firebase;

export const getCostsFromDB = async (user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to access this company');
		}

		let query = await firebase.firestore().collection('companies').doc(company).collection('costs').orderBy('createdAt', 'desc');

		if(user.role === 'user') {
				const start = moment().startOf('day').toDate();
				const end = moment().endOf('day').toDate();
				query = await query.where('createdAt', '>=', firebase.firestore.Timestamp.fromDate(start));
				query = await query.where('createdAt', '<', firebase.firestore.Timestamp.fromDate(end));
		}

		const list = await query.get();

		return list.docs.map((doc) => {
				return {
						id: doc.id,
						...doc.data(),
				}
		});
}

export const createCostInDB = async (data, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
		data.createdByName = user.name;
		data.createdByUserId = user.id;

		const collection = await firebase.firestore().collection('companies').doc(company).collection('costs');
		const docRef = collection.doc();
		await docRef.set(data);

		const formattedDate = moment().format('YYYY-MM-DD');
		const statsRef = firebase.firestore().collection('companies').doc(company).collection('stats').doc(formattedDate);
		const doc = await statsRef.get();

		const companyRef = firebase.firestore().collection('companies').doc(company);

		await companyRef.update({
				totalCosts: firebase.firestore.FieldValue.increment(data.amount),
		});

		if(doc.exists) {
				statsRef.update({
						costs: firebase.firestore.FieldValue.increment(data.amount),
				});
		} else {
				statsRef.set({
						costs: data.amount,
						date: firebase.firestore.Timestamp.fromDate(new Date(formattedDate)),
				});
		}

		return await docRef.get().then(doc => {
				if (doc.exists) {
						return {
								id: doc.id,
								...doc.data(),
						}
				} else {
						return null;
				}
		});
}

