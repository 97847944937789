import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getLocationsFromDB = async (user, company) => {
  if(!company) return Promise.reject('Company is required');

  if(user.role !== 'admin' && user.companyId !== company) {
    return Promise.reject('You are not allowed to access this company');
  }

  const list = await firebase.firestore().collection('locations').where("companyId", "==", company).get();
  return list.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    }
  });
}

export const createLocationInDB = async (data, user) => {
  data.companyId = user.companyId;
  data.createdAt = firebase.firestore.FieldValue.serverTimestamp();

  const collection = firebase.firestore().collection("locations");
  const docRef = collection.doc();
  await docRef.set(data);

  return await docRef.get().then(doc => {
    if (doc.exists) {
      return {
        id: doc.id,
        ...doc.data(),
      }
    } else {
      return null;
    }
  });
}

export const updateLocationInDB = async (data, user, company) => {
  if(user.role === 'admin') {
    if(!company) return Promise.reject('Company is required');
  } else {
    company = user.companyId;
  }

  const collection = firebase.firestore().collection("locations").doc(data.id);

	const snapshot = await collection.get();
	if(!snapshot.exists) {
		return Promise.reject('Service does not exist');
	} else {
		return await collection.update(data);
	}
}

export const deleteLocationFromDB = async (id, user) => {
  const collection = firebase.firestore().collection("locations");
  const docRef = collection.doc(id);
  const data = await docRef.get().then(doc => {
    if (doc.exists) {
      return {
        id: doc.id,
        ...doc.data(),
      }
    } else {
      return null;
    }
  });

  if(!data) return Promise.reject('Location not found');

  if(user.role !== 'admin' && user.companyId !== data.companyId) {
    return Promise.reject('You are not allowed to access this company');
  }

  await docRef.delete();

  return true;
}
