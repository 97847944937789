export const CREATE_CLIENT = "CREATE_CLIENT";

export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";

export const CREATE_CLIENT_FAILED = "CREATE_CLIENT_FAILED";

export const GET_CLIENTS = "GET_CLIENTS";

export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";

export const GET_CLIENTS_FAILED = "GET_CLIENTS_FAILED";

export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";

export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";

export const DELETE_CLIENT = "DELETE_CLIENT";

export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";

export const DELETE_CLIENT_FAILED = "DELETE_CLIENT_FAILED";

export const CLEAR_CLIENTS = "CLEAR_CLIENTS";

export const SELECT_CLIENT = "SELECT_CLIENT";

export const SELECT_CLIENT_SUCCESS = "SELECT_CLIENT_SUCCESS";

export const SELECT_CLIENT_FAILED = "SELECT_CLIENT_FAILED";
