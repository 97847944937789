import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  RESET_REGISTER_FLAG,
  GET_INVITATION,
} from "./actionTypes"

export const registerUser = (user, role, invitation, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, role, invitation, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const resetRegisterFlag = () => {
  return {
    type: RESET_REGISTER_FLAG,
  }
}

export const getInvitation = (token) => {
  return {
    type: GET_INVITATION,
    payload: token,
  }
}

