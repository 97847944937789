import {
	CLEAR_CLIENTS,
	CREATE_CLIENT, CREATE_CLIENT_FAILED,
	CREATE_CLIENT_SUCCESS, DELETE_CLIENT, DELETE_CLIENT_FAILED, DELETE_CLIENT_SUCCESS,
	GET_CLIENTS,
	GET_CLIENTS_FAILED,
	GET_CLIENTS_SUCCESS,
	SELECT_CLIENT,
	SELECT_CLIENT_FAILED,
	SELECT_CLIENT_SUCCESS,
	UPDATE_CLIENT,
	UPDATE_CLIENT_FAILED,
	UPDATE_CLIENT_SUCCESS
} from "./actionTypes";

const defaultState = {
	list: false,
	selected: false,
	loading: false,
	error: null,
	finishedCreate: false,
	latest: '',
}

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case GET_CLIENTS:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case GET_CLIENTS_SUCCESS:
			return {
				...state,
				list: action.payload,
				loading: false,
		}
		case GET_CLIENTS_FAILED:
			return {
				...state,
				error: action.message,
				loading: false,
		}

		case CREATE_CLIENT:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case CREATE_CLIENT_SUCCESS:
			return {
				...state,
				list: state.list ? [...state.list, action.payload] : [action.payload],
				loading: false,
				finishedCreate: true,
				latest: action.payload,
		}
		case CREATE_CLIENT_FAILED:
			return {
				...state,
				error: action.message,
				loading: false,
				finishedCreate: false,
		}

		case UPDATE_CLIENT:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case UPDATE_CLIENT_SUCCESS:
			if(!state.list) {
				return {
					...state,
					loading: false,
					finishedCreate: true,
				}
			}
			return {
				...state,
				loading: false,
				finishedCreate: true,
				list: state.list.map((item) => {
					if(item.ID === action.payload.ID) {
						return {...item, ...action.payload};
					}
					return item;
				}),
		}
		case UPDATE_CLIENT_FAILED:
			return {
				...state,
				error: action.message,
				loading: false,
				finishedCreate: false,
		}

		case DELETE_CLIENT:
			return {
				...state,
				loading: true,
		}
		case DELETE_CLIENT_SUCCESS:
			return {
				...state,
				list: state.list.filter((item) => item.id !== action.payload),
				loading: false,
		}
		case DELETE_CLIENT_FAILED:
			return {
				...state,
				error: action.message,
				loading: false,
		}
		case CLEAR_CLIENTS:
			return {
				...state,
				list: false,
		}

		case SELECT_CLIENT:
			return {
				...state,
				loading: true,
				errorSelect: false
		}

		case SELECT_CLIENT_SUCCESS:
			return {
				...state,
				selected: action.payload,
				loading: false,
		}

		case SELECT_CLIENT_FAILED:
			return {
				...state,
				errorSelect: action.message,
				loading: false,
		}
		default:
			return state;
	}
}
