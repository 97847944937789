import {
	GET_DAILY_ACTIVITY,
	GET_DAILY_ACTIVITY_FAILED,
	GET_DAILY_ACTIVITY_SUCCESS,
	GET_REPORT_BETWEEN_DATES,
	GET_REPORT_BETWEEN_DATES_FAILED,
	GET_REPORT_BETWEEN_DATES_SUCCESS,
} from "./actionTypes";

const defaultState = {
	dailyReport: false,
	dailyActivity: false,
	loading: false,
	loadingDaily: false,
	error: false,
	finishedCreate: false,
}

const reportsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_REPORT_BETWEEN_DATES:
			return {
				...state,
				loading: true,
				finishedCreate: false,
		}
		case GET_REPORT_BETWEEN_DATES_SUCCESS: {
			const data = {
				data: action.payload.data,
				from: action.payload.from,
				to: action.payload.to
			}

			return {
				...state,
				loading: false,
				dailyReport: data,
				finishedCreate: true,
			}
		}
		case GET_REPORT_BETWEEN_DATES_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload,
				finishedCreate: false,
		}

		case GET_DAILY_ACTIVITY:
			return {
				...state,
				loadingDaily: true,
		}

		case GET_DAILY_ACTIVITY_SUCCESS:
			return {
				...state,
				loadingDaily: false,
				dailyActivity: action.payload,
		}

		case GET_DAILY_ACTIVITY_FAILED:
			return {
				...state,
				loadingDaily: false,
				error: action.payload,
		}
		default:
			return state
	}
}

export default reportsReducer;

