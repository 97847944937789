import {
		CREATE_DEPARTMENT, CREATE_DEPARTMENT_FAILED, CREATE_DEPARTMENT_SUCCESS,
		DELETE_DEPARTMENT, DELETE_DEPARTMENT_FAILED,
		DELETE_DEPARTMENT_SUCCESS,
		GET_DEPARTMENTS,
		GET_DEPARTMENTS_FAILED,
		GET_DEPARTMENTS_SUCCESS,
		UPDATE_DEPARTMENT,
		UPDATE_DEPARTMENT_FAILED,
		UPDATE_DEPARTMENT_SUCCESS
} from "./actionTypes";

const defaultState = {
		list: null,
		searchList: null,
		loading: false,
		error: null,
		finishedCreate: false,
}

const departmentsReducer = (state = defaultState, action) => {
		switch (action.type) {
				case GET_DEPARTMENTS:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}
				case GET_DEPARTMENTS_SUCCESS:
						return {
								...state,
								loading: false,
								list: action.payload,
								finishedCreate: false,
						}
				case GET_DEPARTMENTS_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: false,
						}
				case CREATE_DEPARTMENT:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}
				case CREATE_DEPARTMENT_SUCCESS: {
						return {
								...state,
								loading: false,
								list: state.list ? [action.payload, ...state.list] : [action.payload],
								finishedCreate: true,
						}
				}
				case CREATE_DEPARTMENT_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: false,
						}

				case UPDATE_DEPARTMENT:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}

				case	UPDATE_DEPARTMENT_SUCCESS: {
						const list = state.list.map((item) => {
								if(item.id === action.payload.id) {
										return action.payload;
								}
								return item;
						});

						return {
								...state,
								loading: false,
								list,
								finishedCreate: true,
						}
				}

				case UPDATE_DEPARTMENT_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: true,
						}

				case DELETE_DEPARTMENT:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}
				case DELETE_DEPARTMENT_SUCCESS:
						return {
								...state,
								loading: false,
								list: state.list.filter((item) => item.ID !== action.payload),
								finishedCreate: false,
						}
				case DELETE_DEPARTMENT_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: false,
						}
				default:
						return state;
		}
}


export default departmentsReducer;
