import { APIClient } from "./api_helper";

export const getRolesApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/roles`);
}

export const createRoleApi = async (companyId, data) => {
	return await new APIClient().post(`/company/${companyId}/roles`, data);
}

export const getPermissionsApi = async (companyId) => {
	return await new APIClient().get(`/company/${companyId}/permissions`);
}

export const getUserPermissionsApi = async () => {
	return await new APIClient().get(`/permissions`);
}

export const addPermissionToRoleApi = async (companyId, roleId, permissionId) => {
	return await new APIClient().post(`/company/${companyId}/roles/${roleId}/permissions/${permissionId}`);
}

export const removePermissionFromRoleApi = async (companyId, roleId, permissionId) => {
	return await new APIClient().delete(`/company/${companyId}/roles/${roleId}/permissions/${permissionId}`);
}
