import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getServiceProvidersFromDB = async (user, company) => {
		if(!company) return Promise.reject('Company is required');

		const list = await firebase.firestore().collection('companies').doc(company).collection('serviceProviders').get();

		return list.docs.map((doc) => {
				return {
						id: doc.id,
						...doc.data(),
				}
		});
}

export const createServiceProviderInDB = async (data, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
		const collection = await firebase.firestore().collection('companies').doc(company).collection('serviceProviders');
		const docRef = collection.doc();
		await docRef.set(data);

		const spData = await docRef.get().then(doc => {
				if (doc.exists) {
						return {
								id: doc.id,
								...doc.data(),
						}
				} else {
						return null;
				}
		});

		const inviteCollection = firebase.firestore().collection("invites");
		const details = {
				...data,
				companyId: company,
				role: 'user',
				serviceProviderId: spData.id,
				createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		}
		await inviteCollection.add(details);

		return spData;
}

export const updateServiceProviderInDB = async (data, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		const collection = await firebase.firestore().collection('companies').doc(company).collection('serviceProviders').doc(data.id);

		const snapshot = await collection.get();
		if(!snapshot.exists) {
				return Promise.reject('Service provider does not exist');
		} else {
				return await collection.update(data);
		}
}

export const deleteServiceProviderFromDB = async (id, user) => {
		const collection = await firebase.firestore().collection('companies').doc(user.companyId).collection('serviceProviders');
		const docRef = collection.doc(id);
		await docRef.delete();

		return true;
}

export const updateServiceProviderColorInDB = async (id, color, user) => {
		const collection = await firebase.firestore().collection('companies').doc(user.companyId).collection('serviceProviders');
		const docRef = collection.doc(id);
		await docRef.update({color});

		return true;
}

