import { CREATE_SERVICE, UPDATE_SERVICE, DELETE_SERVICE, GET_SERVICES } from "./actionTypes";

export const getServices = (options = {}) => {
  const { search = '', company = '', force = false, fromScheduler = false} = options;
  return {
    type: GET_SERVICES,
    payload: {
      search,
      company,
			force,
			fromScheduler
    },
  }
}

export const createService = (data, company = '') => {
  return {
    type: CREATE_SERVICE,
    payload: {
      data,
      company,
    },
  }
}

export const updateService = (id, data, company = '') => {
	return {
		type: UPDATE_SERVICE,
		payload: {
			id,
			data,
			company,
		},
	}
}

export const deleteService = (id, company = '') => {
  return {
    type: DELETE_SERVICE,
    payload: {
      id,
      company,
    },
  }
}
