import {
	CREATE_CLASS,
	CREATE_CLASS_FAILED,
	CREATE_CLASS_SUCCESS,
	DELETE_CLASS,
	DELETE_CLASS_FAILED,
	DELETE_CLASS_SUCCESS,
	GET_ALL_CLASSES,
	GET_ALL_CLASSES_FAILED,
	GET_ALL_CLASSES_SUCCESS,
	UPDATE_CLASS,
	UPDATE_CLASS_FAILED,
	UPDATE_CLASS_SUCCESS
} from "./actionTypes";

const defaultState = {
	error: null,
	list: false,
	loading: false,
	successCreate: false,
}

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case GET_ALL_CLASSES:
			return {
				...state,
				loading: true
		}

		case GET_ALL_CLASSES_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.payload,
				successCreate: false
		}

		case GET_ALL_CLASSES_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
		}

		case CREATE_CLASS:
			return {
				...state,
				loading: true
		}

		case CREATE_CLASS_SUCCESS:
			if (state.list) {
				return {
					...state,
					loading: false,
					list: [...state.list, action.payload],
					successCreate: true
				}
			}

			return {
				...state,
				loading: false,
				list: [action.payload],
				successCreate: true
		}

		case CREATE_CLASS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
		}

		case UPDATE_CLASS:
			return {
				...state,
				loading: true
		}

		case UPDATE_CLASS_SUCCESS:
			if(!state.list) {
				return {
					...state,
					loading: false,
					successCreate: true
				}
			}
			return {
				...state,
				loading: false,
				list: state.list.map((item) => {
					if (item.id === action.payload.id) {
						return action.payload;
					}
					return item;
				}),
				successCreate: true
		}

		case UPDATE_CLASS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
		}

		case DELETE_CLASS:
			return {
				...state,
				loading: true
		}

		case DELETE_CLASS_SUCCESS:
			return {
				...state,
				loading: false,
				list: state.list.filter((item) => item.ID !== action.payload)
		}

		case DELETE_CLASS_FAILED:
			return {
				...state,
				loading: false,
				error: action.payload
		}

		default:
			return state;
	}
}
