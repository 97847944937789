export const GET_PURCHASES = 'GET_PURCHASES';

export const GET_PURCHASES_SUCCESS = 'GET_PURCHASES_SUCCESS';

export const GET_PURCHASES_FAILED = 'GET_PURCHASES_FAILED';

export const CREATE_PURCHASE = 'CREATE_PURCHASE';

export const CREATE_PURCHASE_SUCCESS = 'CREATE_PURCHASE_SUCCESS';

export const CREATE_PURCHASE_FAILED = 'CREATE_PURCHASE_FAILED';

export const SELECT_PURCHASE = 'SELECT_PURCHASE';

export const SELECT_PURCHASE_SUCCESS = 'SELECT_PURCHASE_SUCCESS';

export const SELECT_PURCHASE_FAILED = 'SELECT_PURCHASE_FAILED';

export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';

export const UPDATE_PURCHASE_SUCCESS = 'UPDATE_PURCHASE_SUCCESS';

export const UPDATE_PURCHASE_FAILED = 'UPDATE_PURCHASE_FAILED';

export const DELETE_PURCHASE = 'DELETE_PURCHASE';

export const DELETE_PURCHASE_SUCCESS = 'DELETE_PURCHASE_SUCCESS';

export const DELETE_PURCHASE_FAILED = 'DELETE_PURCHASE_FAILED';
