import {
  CREATE_SERVICE_PROVIDER,
  CREATE_SERVICE_PROVIDER_FAILURE,
  CREATE_SERVICE_PROVIDER_SUCCESS,
  DELETE_SERVICE_PROVIDER, DELETE_SERVICE_PROVIDER_FAILURE,
  DELETE_SERVICE_PROVIDER_SUCCESS,
  GET_SERVICE_PROVIDERS,
  GET_SERVICE_PROVIDERS_FAILURE,
  GET_SERVICE_PROVIDERS_SUCCESS,
  UPDATE_SERVICE_PROVIDER,
  UPDATE_SERVICE_PROVIDER_FAILURE,
  UPDATE_SERVICE_PROVIDER_SUCCESS,
	UPDATE_SERVICE_PROVIDER_COLOR,
	UPDATE_SERVICE_PROVIDER_COLOR_FAILURE,
	UPDATE_SERVICE_PROVIDER_COLOR_SUCCESS,
} from "./actionTypes";

const defaultState = {
  list: false,
  loading: false,
  error: false,
  finishedCreate: false,
}

const serviceProvidersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_SERVICE_PROVIDERS:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case GET_SERVICE_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        finishedCreate: false,
      }
    case GET_SERVICE_PROVIDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case CREATE_SERVICE_PROVIDER:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case CREATE_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        finishedCreate: true,
      }
    case CREATE_SERVICE_PROVIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
		case UPDATE_SERVICE_PROVIDER:
			return {
				...state,
				loading: true,
				finishedCreate: false,
			}
		case UPDATE_SERVICE_PROVIDER_SUCCESS:
			if(!state.list) {
				return {
					...state,
					loading: false,
					finishedCreate: true,
				}
			}
			return {
				...state,
				loading: false,
				list: state.list.map(item => {
					if(item.ID === action.payload.ID) {
						return {...item, ...action.payload};
					}
					return item;
				}),
				finishedCreate: true,
			}
		case UPDATE_SERVICE_PROVIDER_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
				finishedCreate: false,
			}
    case DELETE_SERVICE_PROVIDER:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case DELETE_SERVICE_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((item) => item.ID !== action.payload),
        finishedCreate: false,
      }
    case DELETE_SERVICE_PROVIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
		case UPDATE_SERVICE_PROVIDER_COLOR:
			return {
				...state,
				loading: true,
			}
		case UPDATE_SERVICE_PROVIDER_COLOR_SUCCESS:
			return {
				...state,
				loading: false,
				list: state.list.map(item => {
					if(item.ID === action.payload.ID) {
						return {...item, color: action.payload.color};
					}
					return item;
				}),
			}
		case UPDATE_SERVICE_PROVIDER_COLOR_FAILURE:
			return {
				...state,
				loading: false,
			}
    default:
      return state
  }
}

export default serviceProvidersReducer;
