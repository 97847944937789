import { takeEvery, takeLatest, select, call, put } from 'redux-saga/effects';
import {
	CREATE_SERVICE, CREATE_SERVICE_FAILED,
	CREATE_SERVICE_SUCCESS, DELETE_SERVICE, DELETE_SERVICE_FAILED, DELETE_SERVICE_SUCCESS,
	GET_SERVICES,
	GET_SERVICES_FAILED,
	GET_SERVICES_SUCCESS,
	UPDATE_SERVICE,
	UPDATE_SERVICE_FAILED,
	UPDATE_SERVICE_SUCCESS
} from './actionTypes';
import {createServiceInDB, updateServiceInDB, deleteServiceFromDB, getServicesFromDB} from "../../helpers/firebase";
import {toastr} from "../toastr/actions";

function* getServices({payload}) {
	try {
		let company = payload.company;
		const user = yield select(state => state.Login.user);

		if(!company) {
			company = user.companyId;
		}

		let search = payload.search;
		let services = yield select(state => state.Services.list);

		if(!services || payload.force) {
			const response = yield call(getServicesFromDB, user, {
				company,
				search,
			}, payload.fromScheduler);
			yield put({ type: GET_SERVICES_SUCCESS, payload: response })
		} else {
			yield put({ type: GET_SERVICES_SUCCESS, payload: services })
		}
	} catch (error) {
		yield put({ type: GET_SERVICES_FAILED, payload: error })
	}
}

function* createService ({payload}) {
	try {
		let company = payload.company;
		let user = yield select(state => state.Login.user);

		if(!company) {
			company = user.companyId;
		}

		const response = yield call(createServiceInDB, user, payload.company, payload.data);

		yield put({ type: CREATE_SERVICE_SUCCESS, payload: response })
		yield put(toastr({
			type: 'success',
			title: 'Successfully created service',
		}));
	} catch (error) {
		yield put(toastr({
			type: 'danger',
			title: 'Error creating service',
		}));
		yield put({ type: CREATE_SERVICE_FAILED, payload: error })
	}
}

function* updateService ({payload}) {
	try {
		let company = payload.company;
		let user = yield select(state => state.Login.user);

		if(!company) {
			company = user.companyId;
		}

		const serviceData = {...payload.data, id: payload.id};
		yield call(updateServiceInDB, user, payload.company, serviceData);

		yield put({ type: UPDATE_SERVICE_SUCCESS, payload: serviceData })
		yield put(toastr({
			type: 'success',
			title: 'Successfully updated service',
		}));

	} catch (e) {
		yield put(toastr({
			type: 'danger',
			title: 'Error updating this service',
		}));
		yield put({ type: UPDATE_SERVICE_FAILED, payload: e})
	}
}

function* deleteService({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		yield call(deleteServiceFromDB, user, payload.company, payload.id);
		yield put({ type: DELETE_SERVICE_SUCCESS, payload: payload.id })
		yield put(toastr({
			type: 'success',
			title: 'Successfully deleted service',
		}));
	} catch (e) {
		yield put(toastr({
			type: 'danger',
			title: 'Error deleting this service',
		}));
		yield put({ type: DELETE_SERVICE_FAILED, payload: e })
	}
}

function* servicesSaga() {
	yield takeLatest(GET_SERVICES, getServices);
	yield takeEvery(CREATE_SERVICE, createService);
	yield takeEvery(UPDATE_SERVICE, updateService);
	yield takeLatest(DELETE_SERVICE, deleteService);
}

export default servicesSaga;
