import { FINISH_EVENT_REMINDER, FINISH_EVENT_REMINDER_FAILED, FINISH_EVENT_REMINDER_SUCCESS, GET_EVENT_REMINDERS, GET_EVENT_REMINDERS_FAILED, GET_EVENT_REMINDERS_SUCCESS } from "./actionTypes";

const defaultState = {
		list: null,
		error: null,
		loading: false,
		loadingReminder: false,
}

const eventRemindersReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_EVENT_REMINDERS:
			return {
				...state, 
				loading: true,
		}

		case GET_EVENT_REMINDERS_FAILED:
			return {
				...state,
				loading: false,
				error: action.message,
		}

		case GET_EVENT_REMINDERS_SUCCESS:
			return {
				...state,
				loading: false,
				list: action.reminders,
		}

		case FINISH_EVENT_REMINDER:
			return {
				...state,
				loadingReminder: true,
		}

		case FINISH_EVENT_REMINDER_SUCCESS:
			return {
				...state,
				loadingReminder: false,
				list: state.list.filter((reminder) => reminder.id !== action.id),
		}

		case FINISH_EVENT_REMINDER_FAILED:
			return {
				...state,
				loadingReminder: false,
				error: action.message
		}

		default:
			return state;
	}

}

export default eventRemindersReducer;

