export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';
export const SELECT_COMPANY = 'SELECT_COMPANY';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';
export const SELECT_COMPANY_FAILED = 'SELECT_COMPANY_FAILED';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';
export const DESELECT_COMPANY = 'DESELECT_COMPANY';
export const CREATE_COMPANY_USER = 'CREATE_COMPANY_USER';
export const CREATE_COMPANY_USER_SUCCESS = 'CREATE_COMPANY_USER_SUCCESS';
export const CREATE_COMPANY_USER_FAILED = 'CREATE_COMPANY_USER_FAILED';
export const SELECT_LOGGED_IN_COMPANY = 'SELECT_LOGGED_IN_COMPANY';
export const SELECT_LOGGED_IN_COMPANY_FAILED = 'SELECT_LOGGED_IN_COMPANY_FAILED';
export const SELECT_LOGGED_IN_COMPANY_SUCCESS = 'SELECT_LOGGED_IN_COMPANY_SUCCESS';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';

export const UPDATE_LOGGED_IN_COMPANY = 'UPDATE_LOGGED_IN_COMPANY';
export const UPDATE_LOGGED_IN_COMPANY_FAILED = 'UPDATE_LOGGED_IN_COMPANY_FAILED';
export const UPDATE_LOGGED_IN_COMPANY_SUCCESS = 'UPDATE_LOGGED_IN_COMPANY_SUCCESS';

export const CLOSE_DAY = 'CLOSE_DAY';
export const CLOSE_DAY_FAILED = 'CLOSE_DAY_FAILED';
export const CLOSE_DAY_SUCCESS = 'CLOSE_DAY_SUCCESS';

export const RESET_COMPANY = 'RESET_COMPANY'
export const RESET_COMPANY_SUCCESS = 'RESET_COMPANY_SUCCESS'
export const RESET_COMPANY_FAIL = 'RESET_COMPANY_FAIL'
