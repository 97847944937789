import {
	CREATE_TRANSACTION,
	DELETE_TRANSACTION,
	GET_CLIENT_UNPAID_TRANSACTIONS,
	GET_TRANSACTIONS,
	GET_CLIENT_TRANSACTIONS,
	SELECT_TRANSACTION,
    CREATE_MANY_TRANSACTIONS,
    RESET_MANY_TRANSACTIONS_DATA
} from "./actionTypes";

export const getTransactions = (options = {}) => {
	const { search = '', company = '' } = options;
	return {
		type: GET_TRANSACTIONS,
		payload: {
			search,
			company
		},
	}
}

export const selectTransaction = (id, company = '') => {
	return {
		type: SELECT_TRANSACTION,
		payload: {
			id,
			company,
		},
	}
}

export const createTransaction = (data, company = '') => {
	return {
		type: CREATE_TRANSACTION,
		payload: {
			data,
			company,
		},
	}
}

export const deleteTransaction = (id, company = '') => {
	return {
		type: DELETE_TRANSACTION,
		payload: {
			id,
			company,
		},
	}
}

export const getClientUnpaidTransactions = (clientId, company = '') => {
	return {
		type: GET_CLIENT_UNPAID_TRANSACTIONS,
		payload: {
			clientId,
			company,
		},
	}
}

export const getClientTransactions = (clientId, company = '') => {
	return {
		type: GET_CLIENT_TRANSACTIONS,
		payload: {
			clientId,
			company,
		},
	}
}

export const createManyTransactions = (transactions, eventData) => {
	return {
		type: CREATE_MANY_TRANSACTIONS,
		payload: {
			transactions: transactions,
			event: eventData
		},
	}
}

export const resetManyData = () => {
	return {
		type: RESET_MANY_TRANSACTIONS_DATA,
	}
}
