import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getDailyReportFromDB, getReportFromDB } from "../../helpers/firebase";
import { toastr } from "../actions";
import { GET_DAILY_ACTIVITY, GET_DAILY_ACTIVITY_SUCCESS, GET_REPORT_BETWEEN_DATES, GET_REPORT_BETWEEN_DATES_FAILED, GET_REPORT_BETWEEN_DATES_SUCCESS } from "./actionTypes";

function* getReportBetweenDates({payload}) {
	try{
		let { from, to, company } = payload;

		let user = yield select(state => state.Login.user);

		if(!company) {
			company = user.companyId;
		}

		const response = yield call(getReportFromDB, from, to, user, company);

		yield put({ type: GET_REPORT_BETWEEN_DATES_SUCCESS, payload: {data: response, from, to} })
	} catch(e) {
		yield put({type: GET_REPORT_BETWEEN_DATES_FAILED, message: e.message})
	}
}

function* getDailyActivityReport({payload}) {
	let { date, company } = payload;

	try {
		let user = yield select(state => state.Login.user);

		if(!company) {
			company = user.companyId;
		}

		const resp = yield call(getDailyReportFromDB, user, company, date);

		yield put({type: GET_DAILY_ACTIVITY_SUCCESS, payload: resp});
	} catch(e) {
		yield put({type: GET_REPORT_BETWEEN_DATES_FAILED, message: e.message})

		yield put(toastr({
			type: 'error',
			message: "Error fetching daily activity report. Please try again."
		}))
	}
}

export default function* serviceProvidersSaga() {
	yield takeEvery(GET_REPORT_BETWEEN_DATES, getReportBetweenDates);
	yield takeLatest(GET_DAILY_ACTIVITY, getDailyActivityReport);
}

