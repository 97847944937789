import React, {useEffect} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {toastr} from "../../store/toastr/actions";
import { withTranslation } from 'react-i18next';

const classNames = {
  info: 'bg-secondary text-white',
  success: 'bg-success text-white',
  error: 'bg-danger text-white',
  danger: 'bg-danger text-white',
  warning: 'bg-warning text-dark',
  primary: 'bg-primary text-white',
  dark: 'bg-dark text-white',
  light: 'bg-light text-dark',
  secondary: 'bg-secondary text-white'
}

const Toastr = ({t}) => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.Toastr.list);

  useEffect(() => {
    if(!notifications.length) return;

    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];

      const type = notification.type ?? 'info';

      toast(t(notification.title), {
        hideProgressBar: notification.hideProgressBar ?? true,
        closeOnClick: notification.closeOnClick ?? true,
        className: classNames[type] ?? classNames['info']
      });

    }

    dispatch(toastr(null));

  }, [dispatch, notifications, t]);

  return (
    <>
      <ToastContainer />
    </>
  )
}

export default withTranslation()(Toastr);
