import flagus from "../assets/images/flags/us.svg";
import mk from "../assets/images/flags/mk.svg";


const languages = {
  en: {
    label: "English",
    flag: flagus,
  },
	mk: {
		label: "Македонски",
		flag: mk,
	},
}

export default languages
