export const MODULES = {
	SUBSCRIPTIONS: 'moduleSubscriptions',
	DEPARTMENTS: 'moduleDepartments',
	CLOSE_DAY: 'moduleCloseDay',
	GROUP_ACTIVITIES: 'moduleGroupActivities',
	SINGLETON_ACTIVITIES: 'moduleSingletonActivities',
	PRODUCTS_AND_SERVICES: 'moduleProductsAndServices',
	CLASSES: 'moduleClasses'
}

export const COMPANY_TYPES = {
	DENTAL: 'companyDental',
	SPORTS: 'companySports',
	EDUCATION: 'companyEducation'
}

export const DAYS = {
	"Sunday": {value: 0},
	"Monday": {value: 1},
	"Tuesday": {value: 2},
	"Wednesday": {value: 3},
	"Thursday": {value: 4},
	"Friday": {value: 5},
	"Saturday": {value: 6}
}
