export const months = [
	"Janury",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
]

export const daysOfWeek = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday"
]

export const getWeekDay = (date) => {
	let dayInt = date.getDay() - 1;
	if(dayInt < 0) {
		dayInt = 6;
	}

	return dayInt;
}

export const getWeekDayName = (date) => {
	return daysOfWeek[getWeekDay(date)];
}

export const getMonthName = (date) => {
	if (typeof date === 'string') {
		date = new Date(date);
	}
	
	if (typeof date === 'number') {
		return months[date];
	}

	return months[date.getMonth()];
}

export const compareDates = (date1, date2) => {
	return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export const isAfter = (date1, date2) => {
	return date1.getTime() >= date2.getTime();
}

export const getAllDaysFromMonth = (month, year) => {
	let days = [];
	let date = new Date(year, month, 1);
	while(date.getMonth() === month) {
		const newDate = new Date(date);
		newDate.setHours(0, 0, 0, 0);
		days.push(newDate);
		date.setDate(date.getDate() + 1);
	}

	return days;
}

export const getVisibleDaysFromMonth = (month, year) => {
	let days = getAllDaysFromMonth(month, year);
	let firstDay = getWeekDay(days[0]);
	let lastDay = getWeekDay(days[days.length - 1]);

	for(let i = 0; i < firstDay; i++) {
		const date = new Date(days[0]);
		date.setDate(date.getDate() - 1);
		days.unshift(date);
	}

	for(let i = lastDay; i < 6; i++) {
		const date = new Date(days[days.length - 1]);
		date.setDate(date.getDate() + 1);
		days.push(date);
	}

	return days;
}
