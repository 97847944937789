import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const getDepartmentsFromDB = async (user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to access this company');
		}

		const list = await firebase.firestore().collection('companies').doc(company).collection('departments').orderBy('createdAt', 'desc').get();

		return list.docs.map((doc) => {
				return {
						id: doc.id,
						...doc.data(),
				}
		});
}

export const createDepartmentInDB = async (data, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		const createdAt = firebase.firestore.FieldValue.serverTimestamp();
		
		const {name, services} = data;

		const collection = await firebase.firestore().collection('companies').doc(company).collection('departments');
		const docRef = collection.doc();
		await docRef.set({name, createdAt});

		const newDepartment = await docRef.get().then(doc => {
				if (doc.exists) {
						return {
								id: doc.id,
								...doc.data(),
						}
				} else {
						return null;
				}
		});

		const batch = firebase.firestore().batch();		
		const servicesCollection = await firebase.firestore().collection('companies').doc(company).collection('services');
		services.forEach(service => {
				const serviceRef = servicesCollection.doc(service);
				batch.update(serviceRef, {department: newDepartment.id});
		});

		await batch.commit();

		return newDepartment;
}

export const updateDepartmentInDB = async (id, data, originalData, user, company) => {
		if(!company) return Promise.reject('Company is required');

		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		const updatedServices = data.services;
		const originalServices = originalData.filter(service => service.department === id).map(service => service.id);

		const toBeAssigned = updatedServices.filter(service => !originalServices.includes(service));
		const toBeUnassigned = originalServices.filter(service => !updatedServices.includes(service));

		const batch = firebase.firestore().batch();
		const servicesCollection = await firebase.firestore().collection('companies').doc(company).collection('services');
		
		toBeAssigned.forEach(service => {
				const serviceRef = servicesCollection.doc(service);
				batch.update(serviceRef, {department: id});
		});

		toBeUnassigned.forEach(service => {
				const serviceRef = servicesCollection.doc(service);
				batch.update(serviceRef, {department: null});
		});

		await batch.commit();
		
		const collection = await firebase.firestore().collection('companies').doc(company).collection('departments');
		await collection.doc(id).update(data);

		return {
				id,
				name: data.name,
				services: [...originalServices.filter(service => !toBeUnassigned.includes(service)), ...toBeAssigned],
		}
}

export const deleteDepartmentFromDB = async (id, user, company) => {
		if(!company) return Promise.reject('Company is required');
		
		if(user.role !== 'admin' && user.companyId !== company) {
				return Promise.reject('You are not allowed to modify this company');
		}

		if(!id) return Promise.reject('Department id is required');

		const batch = firebase.firestore().batch();
		const servicesCollection = await firebase.firestore().collection('companies').doc(company).collection('services');
		const services = await servicesCollection.where('department', '==', id).get();

		services.forEach(service => {
				const serviceRef = servicesCollection.doc(service.id);
				batch.update(serviceRef, {department: null});
		});

		await batch.commit();

		const collection = await firebase.firestore().collection('companies').doc(company).collection('departments');
		await collection.doc(id).delete();

		return id;
}
