import {
	takeEvery,
	takeLatest,
	select,
	put,
	call,
} from 'redux-saga/effects';

import moment from 'moment'

import {
	GET_PURCHASES,
	GET_PURCHASES_FAILED,
	GET_PURCHASES_SUCCESS,
	CREATE_PURCHASE,
	DELETE_PURCHASE,
	CREATE_PURCHASE_FAILED,
	CREATE_PURCHASE_SUCCESS,
	DELETE_PURCHASE_SUCCESS,
	DELETE_PURCHASE_FAILED,
	SELECT_PURCHASE,
	SELECT_PURCHASE_FAILED,
	SELECT_PURCHASE_SUCCESS,
	UPDATE_PURCHASE,
	UPDATE_PURCHASE_FAILED,
	UPDATE_PURCHASE_SUCCESS
} from './actionTypes';

import { selectPurchaseFromDB, getPurchasesFromDB, createPurchaseInDB, updatePurchaseInDB, deletePurchaseFromDB, createTransactionInDB } from '../../helpers/firebase';
import {toastr} from "../toastr/actions";
import { getServices } from '../actions';

function* getPurchases({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;

		if(!company) {
			company = user.companyId;
		}

		const purchases = yield select(state => state.Purchases.list);
		if(!purchases) {
			const response = yield call(getPurchasesFromDB, user, company);
			yield put({type: GET_PURCHASES_SUCCESS, payload: response})
		} else {
			yield put({type: GET_PURCHASES_SUCCESS, payload: purchases});
		}
	} catch (e) {
		yield put({type: GET_PURCHASES_FAILED, message: e.message})
	}
}

function* createPurchase({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		const data = payload.data;
		const response = yield call(createPurchaseInDB, data, user, company);

		yield put({type: CREATE_PURCHASE_SUCCESS, payload: response})
		const debt = {
			amount: data.price,
			type: 'payment',
			debtType: 'Product Purchase',
			client: {
				label: data.client.label,
				value: data.client.value
			},
			createdAt: '',
			currency: 'MKD',
			purchaseId: response.id,
			note: 'Product purchase on ' + moment(new Date()).format("HH:mm DD/MM/YYYY"),
			paid: false,
			paidAmount: 0,
			paidAt: null,
		};
		yield call(createTransactionInDB, debt, user, company);
		yield put(getServices({force: true}));
		yield put(toastr({
			title: 'Purchase created successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Purchase creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: CREATE_PURCHASE_FAILED, message: e.message})
	}
}

function* updatePurchase({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;

		if(!company) {
			company = user.companyId;
		}

		const response = yield call(updatePurchaseInDB, payload.id, payload.data, payload.originalData, user, company);

		yield put({type: UPDATE_PURCHASE_SUCCESS, payload: response})
		yield put(getServices({force: true}));
		yield put(toastr({
			title: 'Purchase created successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put(toastr({
			title: 'Purchase creation failed, please try again.',
			type: 'error'
		}));
		yield put({type: UPDATE_PURCHASE_FAILED, message: e.message})
	}
}

function* deletePurchase({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		yield call(deletePurchaseFromDB, payload.id, user, company);
		yield put({type: DELETE_PURCHASE_SUCCESS, payload: payload.id})
		yield put(toastr({
			title: 'Purchase deleted successfully',
			type: 'success'
		}));
	} catch (e) {
		yield put({type: DELETE_PURCHASE_FAILED, message: e.message})
		yield put(toastr({
			title: 'Error deleting purchase, please try again.',
			type: 'danger'
		}));
	}
}

function* selectPurchase({payload}) {
	try {
		let user = yield select(state => state.Login.user);
		let company = payload.company;
		if(!company) {
			company = user.companyId;
		}

		const purchase = yield call(selectPurchaseFromDB, payload.id, user, company);
		yield put({type: SELECT_PURCHASE_SUCCESS, payload: purchase})

	} catch (e) {
		yield put({type: SELECT_PURCHASE_FAILED, message: e.message})
	}
}

function* purchasesSaga() {
	yield takeEvery(SELECT_PURCHASE, selectPurchase);
	yield takeLatest(GET_PURCHASES, getPurchases);
	yield takeEvery(CREATE_PURCHASE, createPurchase);
	yield takeEvery(UPDATE_PURCHASE, updatePurchase);
	yield takeLatest(DELETE_PURCHASE, deletePurchase);
}

export default purchasesSaga;

