import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = ({
	loading = true,
	backdrop = true,
	fullscreen = false,
}) => {
	if (!loading) {
		return null
	}

	return (
		<div 
			className={`loading ${fullscreen ? "loading-fullscreen" : ""} ${backdrop ? "loading-backdrop" : ""}`} 
			style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%", }}
			>
			<Spinner color="primary" />
		</div>
	);
}

export default Loading;

