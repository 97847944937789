import {
	REGISTER_USER,
	REGISTER_USER_SUCCESSFUL,
	REGISTER_USER_FAILED,
	RESET_REGISTER_FLAG, GET_INVITATION, GET_INVITATION_SUCCESS, GET_INVITATION_FAILED
} from "./actionTypes";

const initialState = {
	registrationError: null,
	invitation: {},
	message: null,
	loading: false,
	user: null,
	success: false,
	error: false,
	invitationError: false,
};

const Account = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_USER:
			state = {
				...state,
				loading: true,
				registrationError: null,
			};
			break;
		case REGISTER_USER_SUCCESSFUL:
			state = {
				...state,
				loading: false,
				success: true,
				registrationError: null,
			};
			break;
		case REGISTER_USER_FAILED:
			state = {
				...state,
				user: null,
				loading: false,
				registrationError: action.payload,
				error: true
			};
			break;
		case RESET_REGISTER_FLAG:
			state = {
				...state,
				success: false,
				error: false,
				invitation: {},
				invitationError: false,
				loading: false
			};
			break;
		case GET_INVITATION:
			state = {
				...state,
				loading: true,
			}
			break;
		case GET_INVITATION_SUCCESS:
			state = {
				...state,
				loading: false,
				invitation: action.payload,
			}
			break;
		case GET_INVITATION_FAILED:
			state = {
				...state,
				loading: false,
				invitationError: true,
			}
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default Account;
