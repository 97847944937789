import {
  CREATE_LOCATION, CREATE_LOCATION_FAILURE,
  CREATE_LOCATION_SUCCESS, DELETE_LOCATION, DELETE_LOCATION_FAILURE, DELETE_LOCATION_SUCCESS,
  GET_LOCATIONS,
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS
} from "./actionTypes";

const defaultState = {
  list: false,
  loading: false,
  error: null,
  finished: false,
  finishedCreate: false,
}

const locationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        finished: true,
        finishedCreate: false,
      }
    case GET_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finished: true,
        finishedCreate: false,
      }
    case CREATE_LOCATION:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list ? [...state.list, action.payload] : [action.payload],
        finishedCreate: true,
      }
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case UPDATE_LOCATION:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        finishedCreate: true,
				list: state.list.map((location) => {
					if(location.id === action.payload.id) {
						return {...location, ...action.payload};
					}

					return location;
				}),
      }
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case DELETE_LOCATION:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((location) => location.id !== action.payload),
        finishedCreate: false,
      }
    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    default:
      return state;
  }
}

export default locationsReducer;
