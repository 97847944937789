import { addDays, startOfDay } from "date-fns";
import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const createReminder = async (_user, company, event) => {
	if(!company) return Promise.reject('Company is required');

	const reminder = {
		eventId: event.id,
		eventDate: firebase.firestore.Timestamp.fromDate(event.start),
		clientId: event.client,
		createdAt: firebase.firestore.Timestamp.now(),
		companyId: company,
		sentAt: null,
	}

	const rmdRef = firebase.firestore().collection('reminders');

	return rmdRef.add(reminder)
		.then(_docRef => {
			return true;
		})
		.catch(error => {
			return Promise.reject(error);
		});
}

export const runReminders = async () => {
	const tomorrow = addDays(startOfDay(new Date()), 1);
	const dayAfter = addDays(startOfDay(new Date()), 2);

	const remindersRef = firebase.firestore().collection("reminders")
		.where("eventDate", ">=", tomorrow)
		.where("eventDate", "<", dayAfter)
		.where("sentAt", "==", null);

	const reminders = await remindersRef.get();

	const remindersByClient = reminders.docs.reduce((acc, doc) => {
		const reminder = doc.data();
		if(!acc[reminder.clientId]) {
			acc[reminder.clientId] = [];
		}
		acc[reminder.clientId].push(reminder);
		return acc;
	}, {});

	let message = "";

	for (const clientId in remindersByClient) {
		const clientDoc = await firebase.firestore().collection("companies").doc(remindersByClient[clientId][0].companyId).collection('clients').doc(clientId).get()
		const client = clientDoc.data();

		if(remindersByClient[clientId].length > 1) {
			console.log("povice od 1");
		} else {
			console.log("ima 1");
			const reminder = remindersByClient[clientId][0];

			console.log("reminder", reminder.eventDate.toDate());
		}
	}

	return false;
}
