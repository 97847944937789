export const GET_SCHEDULER_SERVICES = 'GET_SCHEDULER_SERVICES';

export const GET_SCHEDULER_SERVICES_SUCCESS = 'GET_SCHEDULER_SERVICES_SUCCESS';

export const GET_SCHEDULER_SERVICES_FAILED = 'GET_SCHEDULER_SERVICES_FAILED';

export const GET_SCHEDULER_SPS = 'GET_SCHEDULER_SPS';

export const GET_SCHEDULER_SPS_SUCCESS = 'GET_SCHEDULER_SPS_SUCCESS';

export const GET_SCHEDULER_SPS_FAILED= 'GET_SCHEDULER_SPS_FAILED';

export const GET_SCHEDULER_AVAILABLE_DAYS = 'GET_SCHEDULER_AVAILABLE_DAYS';

export const GET_SCHEDULER_AVAILABLE_DAYS_SUCCESS = 'GET_SCHEDULER_AVAILABLE_DAYS_SUCCESS';

export const GET_SCHEDULER_AVAILABLE_DAYS_FAILED = 'GET_SCHEDULER_AVAILABLE_DAYS_FAILED';

export const GET_SCHEDULER_AVAILABLE_TIMES = 'GET_SCHEDULER_AVAILABLE_TIMES';

export const GET_SCHEDULER_AVAILABLE_TIMES_SUCCESS = 'GET_SCHEDULER_AVAILABLE_TIMES_SUCCESS';

export const GET_SCHEDULER_AVAILABLE_TIMES_FAILED = 'GET_SCHEDULER_AVAILABLE_TIMES_FAILED';

export const SCHEDULE_APPOINTMENT = 'SCHEDULE_APPOINTMENT';

export const SCHEDULE_APPOINTMENT_SUCCESS = 'SCHEDULE_APPOINTMENT_SUCCESS';

export const SCHEDULE_APPOINTMENT_FAILED = 'SCHEDULE_APPOINTMENT_FAILED';

export const SELECT_SCHEDULE_COMPANY = 'SELECT_SCHEDULE_COMPANY';

export const SELECT_SCHEDULE_COMPANY_FAILED = 'SELECT_SCHEDULE_COMPANY_FAILED';

export const SELECT_SCHEDULE_COMPANY_SUCCESS = 'SELECT_SCHEDULE_COMPANY_SUCCESS';

export const CREATE_SCHEDULER_APPOINTMENT = 'CREATE_SCHEDULER_APPOINTMENT';

export const CREATE_SCHEDULER_APPOINTMENT_SUCCESS = 'CREATE_SCHEDULER_APPOINTMENT_SUCCESS';

export const CREATE_SCHEDULER_APPOINTMENT_FAILED = 'CREATE_SCHEDULER_APPOINTMENT_FAILED';

export const CREATE_CLIENT_FROM_SCHEDULER = 'CREATE_CLIENT_FROM_SCHEDULER';
export const CREATE_CLIENT_FROM_SCHEDULER_SUCCESS = 'CREATE_CLIENT_FROM_SCHEDULER_SUCCESS';
export const CREATE_CLIENT_FROM_SCHEDULER_FAILED = 'CREATE_CLIENT_FROM_SCHEDULER_FAILED';

export const GET_SP_UNAVAILABLE_HOURS = 'GET_SP_UNAVAILABLE_HOURS';
export const GET_SP_UNAVAILABLE_HOURS_SUCCESS = 'GET_SP_UNAVAILABLE_HOURS_SUCCESS';
export const GET_SP_UNAVAILABLE_HOURS_FAILED = 'GET_SP_UNAVAILABLE_HOURS_FAILED';

export const DOUBLE_BOOKING = 'DOUBLE_BOOKING';
export const RESET_DOUBLE_BOOKING = 'RESET_DOUBLE_BOOKING';
export const RESET_UNAVAILABLE_HOURS = 'RESET_UNAVAILABLE_HOURS';
