import React, {useEffect} from 'react';
import {Button, Col, Form, FormFeedback, Input, Label, Row, Spinner} from "reactstrap";
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Navigate} from 'react-router-dom';
import { createTransaction, getServices, getClients, getDepartments } from '../../../store/actions';

const CreateTransactionForm = ({onPriceChange=null, target = '', targetId = '', isModal = false, fixedType = '', toggle, redirectTo = '', client = null, t, max=null}) => {
	const clients = useSelector(state => state.Clients.list);
	const items = useSelector(state => state.Services.list);
	const departments = useSelector(state => state.Departments.list);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getClients());
		}, [dispatch, clients]);

	useEffect(() => {
		dispatch(getServices());
		}, [dispatch, items]);

	useEffect(() => {
		dispatch(getDepartments());
		}, [dispatch, departments]);

	const finishedCreate = useSelector(state => state.Transactions.finishedCreate);
	const loading = false;
	const [data, setData] = React.useState({
		type: fixedType || 'payment',
		client: client,
		amount: '',
		currency: 'MKD',
		product: '',
		productAmount: '',
		paymentMethod: 'cash',
		department: null,
		note: '',
		target: target,
		targetId: targetId,
		receipt: false,
		receiptAmount: 0,
		paidEUR: 0
	});

	let amountValidation = Yup.number().required(t("Amount is required")).nullable();
	if(max) {
		amountValidation = amountValidation.max(max, 'The max is '+ max);
	}
	const validation = useFormik({
		initialValues: data,
		enableReinitialize: true,
		validationSchema: Yup.object({
			type: Yup.string().required(t("Type is required")),
			client: Yup.object().required(t("Client is required")),
			amount: amountValidation,
			currency: Yup.string().required(t("Currency is required")),
			paymentMethod: Yup.string(),
			department: Yup.object().required(t("Department is required")),
			note: Yup.string(),
		}),
		onSubmit: (values) => {
			dispatch(createTransaction(values));
			toggle();
		}
	});

	const handleChangeReceiptAmount = (e) => {
		let {value} = e.target;

		if(parseInt(value) > parseInt(data.amount)) {
			value = data.amount;
		}

		setData({...data, receiptAmount: value});
	}

	const handlePercentage = (percentage) => {
		const amount = data.amount;
		const receiptAmount = parseInt(amount * percentage / 100);

		setData({...data, receiptAmount: receiptAmount});
	}

	const handleChange = (e) => {
		let {name, value, checked, type} = e.target;

		let transactionData = {};

		if(type === 'checkbox') {
			value = checked;
		}

		if(name === 'amount' && max) {
			if(value > max) {
				value = max;
			}
		}

		if(name === 'amount') {
			transactionData.receiptAmount = value;

			if(onPriceChange) {
				onPriceChange(value);
			}
		}

		if(name === 'clientId') {
			setData({
				...data,
				...transactionData,
			});
		} else {
			setData({...data, ...transactionData, [name]: value});
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		validation.handleSubmit();
	}

	return (
		<Form className="form-steps" onSubmit={handleSubmit}>
			{finishedCreate && redirectTo ? (
				<Navigate to={redirectTo} replace={true} />
				) : null}
			<Row>
				{ !fixedType ? (
					<>
						<Col md={6}>
							<div className="mb-3">
								<Label for="type" className="form-label">{t("Type")}</Label>
								<Input
									type="select" className="form-control" name="type"
									id="type"
									onBlur={validation.handleBlur}
									invalid={validation.errors.name && validation.touched.name}
									onChange={handleChange}
									value={data.type}
									>
									<option value="payment">{t("Payment")}</option>
									<option style={{color: 'red'}} value="debt">{t("Debt")}</option>
								</Input>
								<small>
									{data.type === 'payment' ? (
										<span className="text-success">{t("This option will decrease the client's debt")}</span>
									): null}
									{data.type === 'debt' ? (
										<span className="text-danger">{t("This option will increase the client's debt")}</span>
									): null}
								</small>
							</div>
						</Col>
						<Col md={6}></Col>
					</>
					) : null }

				<Col md={6}>
					<div className="mb-3">
						<Label for="client" className="form-label">{t("Client")}</Label>
						{
							client ? (
								" - " + client.label
								) : (
									<>
										<Select
											name="client"
											id="client"
											onBlur={validation.handleBlur}
											onChange={(e) => setData({...data, client: e})}
											options={clients && clients.map(client => ({value: client.id, label: client.name}))}
										/>
										{validation.errors.client && validation.touched.client? (
											<small className='text-danger' type="invalid">{validation.errors.client}</small>
										) : null}
									</>
							)
						}
					</div>
				</Col>
			<hr />

				<Col md={6}>
					<div className="mb-3">
						<Label for="amount" className="form-label">{data.currency} {t("Amount")}</Label>
						<Input
							name="amount"
							type="number"
							className="form-control d-block"
							aria-label="Default select example"
							onBlur={validation.handleBlur}
							invalid={validation.errors.amount && validation.touched.amount}
							onChange={handleChange}
							value={data.amount}
						></Input>
						{validation.errors.amount && validation.touched.amount? (
							<FormFeedback type="invalid">{validation.errors.amount}</FormFeedback>
							) : null}
					</div>
				</Col>
				<Col md={6}>
					<div className="mb-3">
						<Label for="paymentMethod" className="form-label">{t("Payment method")}</Label>
						<Input
							name="paymentMethod"
							type="select"
							className="form-control d-block"
							aria-label="Default select example"
							onBlur={validation.handleBlur}
							onChange={handleChange}
							value={data.paymentMethod}
							>
							<option value="cash">{t("Cash")}</option>
							<option value="card">{t("Card")}</option>
						</Input>
					</div>
				</Col>
				<Col md={6}>
					<div className="mb-3">
						<Label for="department" className="form-label">{t("Department")}</Label>
						<Select
							name="department"
							id="department"
							placeholder={t("Select department")}
							value={validation.values.department}
							onChange={(e) => setData({...data, department: e})}
							options={departments && departments.map(department => ({value: department.id, label: department.name}))}
							className={"js-example-basic-single mb-0 "}
							isLoading={loading}
							required
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={6} xl={6}>
					<div className="mb-3 pt-3 form-check">
						<Input
							className="form-check-input"
							type="checkbox"
							id="receipt"
							name="receipt"
							checked={data.receipt}
							onChange={handleChange}
						/>
						<Label for="receipt" className="form-check-label">{t("Receipt")}</Label>
					</div>
				</Col>
				{ data.receipt ? (
					<Col md={6} xl={6}>
						<div className="mb-3" >
							<Label for="receiptAmount" className="form-label">{t("Receipt Amount")}</Label>
							<Input
								type="number"
								name="receiptAmount"
								className="form-control"
								id="receiptAmount"
								placeholder={t("Receipt Amount")}
								value={data.receiptAmount}
								onChange={handleChangeReceiptAmount}
								max={data.amount}
							/>
						</div>
						<div>
							{ data.amount > 100 && data.paymentMethod === "cash" ? (
								<>
									<Button type="button" color='link' className='btn-link mt-0 mb-0 p-0 mr-3' onClick={() => handlePercentage(100)}>
										<small>
											{t("Full sum")}
										</small>
									</Button>
									<Button type="button" color='link' className='btn-link mt-0 mb-0 p-0 mr-3' onClick={() => handlePercentage(75)}>
										<small>
											75%
										</small>
									</Button>
									<Button type="button" color='link' className='btn-link mt-0 mb-0 p-0 mr-3' onClick={() => handlePercentage(50)}>
										<small>
											50%
										</small>
									</Button>
									<Button type="button" color='link' className='btn-link mt-0 mb-0 p-0 mr-3' onClick={() => handlePercentage(25)}>
										<small>
											25%
										</small>
									</Button>
								</>
							) : null }
						</div>
					</Col>
					) : null }
			</Row>
			<Row>
				<Col md={12} xl={12} className="mb-3">
					<div className="mb-3">
						<Label for="note" className="form-label">{t("Note")}</Label>
						<Input
							type="textarea"
							name="note"
							className="form-control"
							id="note"
							placeholder={t("Note")}
							onChange={handleChange}
						/>
					</div>
				</Col>
				<Col xs={12}>
					<div className="text-end">
						{
							isModal ? (
								<button type="button" onClick={toggle} className="btn btn-ghost-dark" style={{marginRight: 3}}>{t("Cancel")}</button>
								) : null
						}
						<Button type="submit" color="primary" className="btn-load" disabled={loading}>
							<span className="d-flex align-items-center">
								<span className={"flex-grow-1 " + (loading ? "me-2" : "")}>
									{t("Create")}
								</span>
								{loading ? ( <Spinner size="sm" className="flex-shrink-0" role="status" /> ) : null}
							</span>
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

export default withTranslation()(CreateTransactionForm);

