import { takeEvery, put, call, select, takeLatest } from "redux-saga/effects"
// Calender Redux States
import {
	ADD_NEW_EVENT,
	DELETE_EVENT,
	GET_CATEGORIES,
	GET_EVENTS,
	UPDATE_EVENT,
	GET_UPCOMMINGEVENT,
	CANCEL_EVENT,
	CANCEL_EVENT_FAIL,
	CANCEL_EVENT_SUCCESS,
	PLACEHOLDER_EVENT,
	CLEAR_PLACEHOLDER_EVENT,
	RESET_CREATE,
	GET_EVENT,
	GET_EVENT_SUCCESS,
	GET_EVENT_FAIL,
	CONFIRM_EVENT,
	CONFIRM_EVENT_SUCCESS,
	CONFIRM_EVENT_FAIL,
	ADD_GROUP_EVENT,
	ADD_GROUP_EVENT_SUCCESS,
	ADD_GROUP_EVENT_FAIL,
    GET_RECURRING_EVENTS
} from "./actionTypes"

import {
	getEventsSuccess,
	getEventsFail,
	addEventFail,
	addEventSuccess,
	updateEventSuccess,
	updateEventFail,
	deleteEventSuccess,
	deleteEventFail,
	getCategoriesSuccess,
	getCategoriesFail,
	getUpCommingEventSuccess,
	getUpCommingEventFail
} from "./actions"
import { createEventInDB, getEventFromDB, getRecurringEventsFromDB, getEventsFromDB, updateEventInDB, cancelEventInDB, confirmEventInDB, createGroupEventInDB } from "../../helpers/firebase/events.firebase";
import { toastr } from "../toastr/actions";
import { createClientReminder } from "../../helpers/firebase";

function* fetchRecurringEvents({ payload }) {
	try {
		const user = yield select((state) => state.Login.user);
		const company = user.companyId;
		const response = yield call(getRecurringEventsFromDB, user, company, payload);

		yield put(getEventsSuccess(response))
	} catch (error) {
		yield put(getEventsFail(error))
	}
}

function* fetchEvents({ payload }) {
	try {
		const user = yield select((state) => state.Login.user);
		let company = payload.company ?? user.companyId;
		const response = yield call(getEventsFromDB, user, company, payload);

		yield put(getEventsSuccess(response))
	} catch (error) {
		yield put(getEventsFail(error))
	}
}

function* onAddNewGroupEvent({ payload }) {
	try {
		const user = yield select((state) => state.Login.user)
		const company = user.companyId;

		const response = yield call(createGroupEventInDB, payload, user, company);

		yield put(toastr({
			type: 'success',
			title: 'Successfully added appointment to calendar.',
		}));

		yield put({ type: ADD_GROUP_EVENT_SUCCESS, payload: response });
	} catch (error) {
		console.log(error);
		yield put(toastr({
			type: 'danger',
			title: 'Error cancelling appointment',
		}));
		yield put({ type: ADD_GROUP_EVENT_FAIL, payload: error });
	}
}

function* onAddNewEvent({ payload: event, company = null }) {
	try {
		const user = yield select((state) => state.Login.user)
		if (!company) {
			company = user.companyId;
		}
		const response = yield call(createEventInDB, event, user, company);

		const client = yield select(state => state.Clients.list.find(client => client.id === event.client));

		yield call(createClientReminder, user, company, response, client);

		yield put(addEventSuccess(response))
		yield put(toastr({
			type: 'success',
			title: 'Successfully added appointment to calendar.',
		}));
	} catch (error) {
		yield put(addEventFail(error))
	}
}

function* onUpdateEvent({ payload: event, company = null }) {
	try {
		const user = yield select((state) => state.Login.user)
		if (!company) {
			company = user.companyId;
		}
		yield call(updateEventInDB, event, user, company);
		yield put(updateEventSuccess(event))
		yield put(toastr({
			title: 'Event updated successfully',
			type: 'success'
		}));
	} catch (error) {
		yield put(toastr({
			title: 'Could not update event, try again later.',
			type: 'error'
		}));
		yield put(updateEventFail(error))
	}
}

function* onDeleteEvent({ payload: event }) {
	try {
		// const response = yield call(deleteEvent, event)
		const response = [];
		yield put(deleteEventSuccess(response))
	} catch (error) {
		yield put(deleteEventFail(error))
	}
}

function* onGetCategories() {
	try {
		// const response = yield call(getCategories)
		const response = [];
		yield put(getCategoriesSuccess(response))
	} catch (error) {
		yield put(getCategoriesFail(error))
	}
}

function* onGetUpcommingEvents() {
	try {
		// const response = yield call(getUpCommingEvent)
		const response = [];
		yield put(getUpCommingEventSuccess(response))
	} catch (error) {
		yield put(getUpCommingEventFail(error))
	}
}

function* confirmAppointment({ payload }) {
	try {
		let company = payload.company;
		const user = yield select(state => state.Login.user);

		if (!company) {
			company = user.companyId;
		}

		yield call(confirmEventInDB, user, company, payload.id);

		yield put(toastr({
			type: 'success',
			title: 'Appointment has been confirmed',
		}));

		yield put({ type: CONFIRM_EVENT_SUCCESS, payload: payload.id });

	} catch (error) {
		yield put(toastr({
			type: 'danger',
			title: 'Error confirming this appointment',
		}));
		yield put({ type: CONFIRM_EVENT_FAIL, payload: error });
	}
}

function* cancelAppointment({ payload }) {
	try {
		let company = payload.company;
		const user = yield select(state => state.Login.user);

		if (!company) {
			company = user.companyId;
		}

		yield call(cancelEventInDB, user, company, payload.event, payload.reason);

		yield put(toastr({
			type: 'success',
			title: 'Successfully cancelled appointment',
		}));
		yield put({ type: CANCEL_EVENT_SUCCESS, payload: payload.event });

	} catch (error) {
		yield put(toastr({
			type: 'danger',
			title: 'Error cancelling appointment',
		}));
		yield put({ type: CANCEL_EVENT_FAIL, payload: error });
	}
}

function* placeHolderEvent(payload) {
	try {
		const event = {
			id: "placeholder",
			title: "New appointment",
			start: payload.start,
			end: payload.end,
			serviceProvider: ''
		};
		yield put({ type: PLACEHOLDER_EVENT, event });
	} catch (e) {
		console.log(e);
	}
}

function* clearPlaceHolderEvent() {
	try {
		yield put({ type: CLEAR_PLACEHOLDER_EVENT });
	} catch (e) {
		console.log(e);
	}
}

function* resetCreate() {
	try {
		yield put({ type: RESET_CREATE });
	} catch (e) {
		console.log(e);
	}
}

function* getEvent({ payload }) {
	try {
		const user = yield select(state => state.Login.user);
		const company = payload.company ? payload.company : user.companyId;

		const response = yield call(getEventFromDB, user, company, payload.id);

		yield put({ type: GET_EVENT_SUCCESS, payload: response });
	} catch (error) {
		yield put({ type: GET_EVENT_FAIL, payload: error });
	}
}

function* calendarSaga() {
	yield takeEvery(GET_EVENTS, fetchEvents)
	yield takeEvery(GET_RECURRING_EVENTS, fetchRecurringEvents)
	yield takeLatest(PLACEHOLDER_EVENT, placeHolderEvent);
	yield takeEvery(ADD_NEW_EVENT, onAddNewEvent)
	yield takeEvery(UPDATE_EVENT, onUpdateEvent)
	yield takeEvery(DELETE_EVENT, onDeleteEvent)
	yield takeEvery(GET_CATEGORIES, onGetCategories)
	yield takeEvery(GET_UPCOMMINGEVENT, onGetUpcommingEvents)
	yield takeEvery(CANCEL_EVENT, cancelAppointment)
	yield takeEvery(CONFIRM_EVENT, confirmAppointment)
	yield takeEvery(CLEAR_PLACEHOLDER_EVENT, clearPlaceHolderEvent)
	yield takeEvery(RESET_CREATE, resetCreate)
	yield takeEvery(GET_EVENT, getEvent)
	yield takeEvery(ADD_GROUP_EVENT, onAddNewGroupEvent)
}

export default calendarSaga
