export const GET_COSTS = 'GET_COSTS';

export const GET_COSTS_SUCCESS = 'GET_COSTS_SUCCESS';

export const GET_COSTS_FAILED = 'GET_COSTS_FAILED';

export const CREATE_COST = 'CREATE_COST';

export const CREATE_COST_SUCCESS = 'CREATE_COST_SUCCESS';

export const CREATE_COST_FAILED = 'CREATE_COST_FAILED';

export const UPDATE_COST = 'UPDATE_COST';

export const UPDATE_COST_SUCCESS = 'UPDATE_COST_SUCCESS';

export const UPDATE_COST_FAILED = 'UPDATE_COST_FAILED';

export const DELETE_COST = 'DELETE_COST';

export const DELETE_COST_SUCCESS = 'DELETE_COST_SUCCESS';

export const DELETE_COST_FAILED = 'DELETE_COST_FAILED';
