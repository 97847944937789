import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api/api_helper";
import { selectLoggedInCompanySuccess } from "../store/actions";

const AuthProtected = (props) => {
	const user = getLoggedinUser();
	const dispatch = useDispatch();
	const company = useSelector((state) => state.Company.loggedInCompany);

	useEffect(() => {
		if(user && user.companyID && !Object.keys(company).length) {
			dispatch(selectLoggedInCompanySuccess(user.company));
		}
	}, [dispatch, user, company]);

  if (!user) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
