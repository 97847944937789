import {
		CREATE_PURCHASE, CREATE_PURCHASE_FAILED, CREATE_PURCHASE_SUCCESS,
		DELETE_PURCHASE, DELETE_PURCHASE_FAILED,
		DELETE_PURCHASE_SUCCESS,
		GET_PURCHASES,
		GET_PURCHASES_FAILED, 
		GET_PURCHASES_SUCCESS,
		SELECT_PURCHASE,
		SELECT_PURCHASE_FAILED,
		SELECT_PURCHASE_SUCCESS,
		UPDATE_PURCHASE,
		UPDATE_PURCHASE_FAILED,
		UPDATE_PURCHASE_SUCCESS
} from "./actionTypes";

const defaultState = {
		list: null,
		searchList: null,
		loading: false,
		error: null,
		finishedCreate: false,
		selected: null,
		selectedId: null,
}

const purchasesReducer = (state = defaultState, action) => {
		switch (action.type) {
				case GET_PURCHASES:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}
				case GET_PURCHASES_SUCCESS:
						return {
								...state,
								loading: false,
								list: action.payload,
								finishedCreate: false,
						}
				case GET_PURCHASES_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: false,
						}
				case CREATE_PURCHASE:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}
				case CREATE_PURCHASE_SUCCESS: {
						return {
								...state,
								loading: false,
								list: state.list ? [action.payload, ...state.list] : [action.payload],
								finishedCreate: action.payload.id,
						}
				}
				case CREATE_PURCHASE_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: false,
						}

				case SELECT_PURCHASE:
						return {
								...state,
								loading: true,
						}

				case SELECT_PURCHASE_SUCCESS:
						return {
								...state,
								loading: false,
								selected: action.payload,
								selectedId: action.payload.id
						}

				case SELECT_PURCHASE_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
						}

				case UPDATE_PURCHASE:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}

				case	UPDATE_PURCHASE_SUCCESS: {
						const list = state.list.map((item) => {
								if(item.id === action.payload.id) {
										return action.payload;
								}
								return item;
						});

						return {
								...state,
								loading: false,
								list,
								finishedCreate: true,
						}
				}

				case UPDATE_PURCHASE_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: true,
						}

				case DELETE_PURCHASE:
						return {
								...state,
								loading: true,
								finishedCreate: false,
						}
				case DELETE_PURCHASE_SUCCESS:
						return {
								...state,
								loading: false,
								list: state.list.filter((item) => item.id !== action.payload),
								finishedCreate: false,
						}
				case DELETE_PURCHASE_FAILED:
						return {
								...state,
								loading: false,
								error: action.payload,
								finishedCreate: false,
						}
				default:
						return state;
		}
}


export default purchasesReducer;
