export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILED = 'CREATE_TRANSACTION_FAILED';

export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILED = 'UPDATE_TRANSACTION_FAILED';

export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED';

export const GET_CLIENT_UNPAID_TRANSACTIONS = 'GET_CLIENT_UNPAID_TRANSACTIONS';
export const GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS = 'GET_CLIENT_UNPAID_TRANSACTIONS_SUCCESS';
export const GET_CLIENT_UNPAID_TRANSACTIONS_FAILED = 'GET_CLIENT_UNPAID_TRANSACTIONS_FAILED';

export const GET_CLIENT_TRANSACTIONS = 'GET_CLIENT_TRANSACTIONS';
export const GET_CLIENT_TRANSACTIONS_SUCCESS = 'GET_CLIENT_TRANSACTIONS_SUCCESS';
export const GET_CLIENT_TRANSACTIONS_FAILED = 'GET_CLIENT_TRANSACTIONS_FAILED';

export const SELECT_TRANSACTION = 'GET_TRANSACTION';
export const SELECT_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const SELECT_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED';

export const CREATE_MANY_TRANSACTIONS = 'CREATE_MANY_TRANSACTIONS';
export const CREATE_MANY_TRANSACTIONS_SUCCESS = 'CREATE_MANY_TRANSACTIONS_SUCCESS';
export const CREATE_MANY_TRANSACTIONS_FAILED = 'CREATE_MANY_TRANSACTIONS_FAILED';

export const RESET_MANY_TRANSACTIONS_DATA = 'RESET_MANY_TRANSACTIONS_DATA';
