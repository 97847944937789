import {
	GET_EVENTS_FAIL,
	GET_EVENTS_SUCCESS,
	GET_CATEGORIES_FAIL,
	GET_CATEGORIES_SUCCESS,
	ADD_EVENT_SUCCESS,
	ADD_EVENT_FAIL,
	UPDATE_EVENT_SUCCESS,
	UPDATE_EVENT_FAIL,
	DELETE_EVENT_SUCCESS,
	DELETE_EVENT_FAIL,
	RESET_CALENDAR,
	GET_UPCOMMINGEVENT_SUCCESS,
	GET_UPCOMMINGEVENT_FAIL,
	CANCEL_EVENT,
	CANCEL_EVENT_SUCCESS,
	CANCEL_EVENT_FAIL,
	PLACEHOLDER_EVENT,
	CLEAR_PLACEHOLDER_EVENT,
	ADD_NEW_EVENT,
	RESET_CREATE,
	GET_EVENTS,
	GET_EVENT,
	GET_EVENT_SUCCESS,
	GET_EVENT_FAIL,
	CONFIRM_EVENT_SUCCESS,
	RESET_EVENTS,
	UPDATE_EVENT,
    SET_LOADING_UPDATE_EVENT
} from "./actionTypes";

const INIT_STATE = {
	events: [],
	placeholder: [],
	categories: [],
	upcommingevents: [],
	error: false,
	finishedDelete: false,
	finishedCreate: false,
	loadingUpdate: false,
	loadingCreate: false,
	loading: false,
	selectedEvent: null,
};

const Calendar = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_EVENTS:
			return {
				...state,
				loading: true,
		};
		case GET_EVENTS_SUCCESS: {
			const oldEvents = state.events;
			const newEvents = action.payload;

			if(oldEvents.length === 0) {
				return {
					...state,
					events: newEvents,
					loading: false,
				}
			}
			const mergedEvents = [];

			oldEvents.forEach((oldEvent) => {
				// if mergedEvents already contains the oldEvent, don't add it again
				if(mergedEvents.find((mergedEvent) => mergedEvent.id === oldEvent.id)) {
					return;
				} else {
					mergedEvents.push(oldEvent);
				}
			});

			newEvents.forEach((newEvent) => {
				// if mergedEvents already contains the oldEvent, don't add it again
				if(mergedEvents.find((mergedEvent) => mergedEvent.id === newEvent.id)) {
					return;
				} else {
					mergedEvents.push(newEvent);
				}
			});

			return {
				...state,
				events: mergedEvents,
				loading: false,
			};
		}
		case GET_EVENTS_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false,
		};

		case ADD_NEW_EVENT:
			return {
				...state,
				loadingCreate: true,
				error: false,
		}

		case ADD_EVENT_SUCCESS:
			return {
				...state,
				loadingCreate: false,
				finishedCreate: true,
				events: [...state.events, action.payload],
		};

		case ADD_EVENT_FAIL:
			return {
				...state,
				loadingCreate: false,
				error: action.payload,
		};

		case UPDATE_EVENT:
			return {
				...state,
				loadingUpdate: true
		}

		case UPDATE_EVENT_SUCCESS:
			return {
				...state,
				loadingUpdate: false,
				events: state.events.map((event) => {
					if(event.id === action.payload.id) {
						return {
							...event,
							...action.payload
						}
					}

					return event;
				}),
				isEventUpdated: true,
		};

		case UPDATE_EVENT_FAIL:
			return {
				...state,
				loadingUpdate: false,
				error: action.payload,
				isEventUpdated: false,
		};

		case DELETE_EVENT_SUCCESS:
			return {
				...state,
				events: state.events.filter(
					(event) => event.id !== action.payload
				),
		};

		case DELETE_EVENT_FAIL:
			return {
				...state,
				error: action.payload,
		};

		case GET_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: action.payload,
		};

		case GET_CATEGORIES_FAIL:
			return {
				...state,
				error: action.payload,
		};

		case GET_UPCOMMINGEVENT_SUCCESS:
			return {
				...state,
				upcommingevents: action.payload,
		};

		case GET_UPCOMMINGEVENT_FAIL:
			return {
				...state,
				error: action.payload,
		};

		case RESET_CALENDAR:
			var flag = action.payload.flag;
			var value = action.payload.value;
			var flags = {};
			flags[flag] = value;

			return {
				...state,
				...flags,
		};

		case CANCEL_EVENT:
			return {
				...state,
				loading: true,
				finishedDelete: false,
		};

		case CANCEL_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				events: state.events.filter((event) => event.id !== action.payload),
				finishedDelete: true,
		};

		case CANCEL_EVENT_FAIL:
			return {
				...state,
				loading: true,
				finishedDelete: false,
		};

		case PLACEHOLDER_EVENT:
			return {
				...state,
				placeholders: [action.payload],
		};

		case CLEAR_PLACEHOLDER_EVENT:
			return {
				...state,
				placeholders: [],
		}

		case RESET_CREATE:
			return {
				...state,
				finishedCreate: false,
		}

		case GET_EVENT:
			return {
				...state,
				loading: true,
		};

		case GET_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				selectedEvent: action.payload,
		};

		case GET_EVENT_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
		};

		case CONFIRM_EVENT_SUCCESS: {
			return {
				...state,
				loading: false,
				events: state.events.map((event) => {
					if(event.id === action.payload) {
						event.status = "Confirmed"
					}
					return event;
				})
			}
		}

		case RESET_EVENTS: {
			return {
				...state,
				events: [],
			}
		}
		case SET_LOADING_UPDATE_EVENT: {
			return {
				...state,
				loadingUpdate: true
			}
		}
		default:
			return state;
	}
};

export default Calendar;
