export const clientsMenuData = [
	{
		id: "clients",
		label: "Clients",
		icon: "ri-calendar-event-line",
		link: "/clients",
		subItems: [
			{
				id: "clients-list",
				label: "Clients List",
				link: "/clients",
				parentId: "clients",
			},
			{
				id: "subscriptions-list",
				label: "Subscriptions",
				link: "/subscriptions",
				parentId: "settings"
			},
			{
				id: "registrations-list",
				label: "Registrations",
				link: "/registrations",
				parentId: "settings"
			},
			{
				id: "purchases-list",
				label: "Purchases",
				link: "/purchases",
				parentId: "clients",
			},
			{
				id: "transactions-list",
				label: "Transactions",
				link: "/transactions",
				parentId: "clients",
			},
			{
				id: "costs-list",
				label: "Costs",
				link: "/costs",
				parentId: "clients",
			}
		]
	},
];
