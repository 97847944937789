import {
  CREATE_SERVICE, CREATE_SERVICE_FAILED, CREATE_SERVICE_SUCCESS,
  DELETE_SERVICE, DELETE_SERVICE_FAILED,
  DELETE_SERVICE_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAILED, GET_SERVICES_SEARCH_SUCCESS,
  GET_SERVICES_SUCCESS,
	UPDATE_SERVICE, UPDATE_SERVICE_FAILED, UPDATE_SERVICE_SUCCESS
} from "./actionTypes";

const defaultState = {
  list: null,
  searchList: null,
  loading: false,
  error: null,
  finishedCreate: false,
}

const serviceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        finishedCreate: false,
      }
    case GET_SERVICES_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchList: action.payload,
        finishedCreate: false,
      }
    case GET_SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case CREATE_SERVICE:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list ? [...state.list, action.payload] : [action.payload],
        finishedCreate: true,
      }
    case CREATE_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case UPDATE_SERVICE:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        finishedCreate: true,
				list: state.list.map((item) => {
					if(item.id === action.payload.id) {
						return {...item, ...action.payload};
					}
					return item;
				}),
      }
    case UPDATE_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    case DELETE_SERVICE:
      return {
        ...state,
        loading: true,
        finishedCreate: false,
      }
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((item) => item.id !== action.payload),
        finishedCreate: false,
      }
    case DELETE_SERVICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        finishedCreate: false,
      }
    default:
      return state;
  }
}


export default serviceReducer;
