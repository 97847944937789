export const GET_SERVICES = 'GET_SERVICES';

export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';

export const GET_SERVICES_SEARCH_SUCCESS = 'GET_SERVICES_SEARCH_SUCCESS';

export const GET_SERVICES_FAILED = 'GET_SERVICES_FAILED';

export const CREATE_SERVICE = 'CREATE_SERVICE';

export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';

export const CREATE_SERVICE_FAILED = 'CREATE_SERVICE_FAILED';

export const UPDATE_SERVICE = 'UPDATE_SERVICE';

export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';

export const UPDATE_SERVICE_FAILED = 'UPDATE_SERVICE_FAILED';

export const DELETE_SERVICE = 'DELETE_SERVICE';

export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';

export const DELETE_SERVICE_FAILED = 'DELETE_SERVICE_FAILED';