import { format, isBefore, addDays, isAfter, differenceInMinutes } from 'date-fns';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getVisibleDaysFromMonth } from '../../helpers/datepicker_helper';
import { createClientInDB, getClientByPhone, selectScheduleCompanyFromDB } from '../../helpers/firebase';
import { createEventInDB, getEventsBySP } from '../../helpers/firebase/events.firebase';
import { toastr } from '../actions';
import {
	CREATE_SCHEDULER_APPOINTMENT, 
	CREATE_SCHEDULER_APPOINTMENT_FAILED, 
	CREATE_SCHEDULER_APPOINTMENT_SUCCESS, 
	DOUBLE_BOOKING, 
	GET_SP_UNAVAILABLE_HOURS, 
	GET_SP_UNAVAILABLE_HOURS_FAILED, 
	GET_SP_UNAVAILABLE_HOURS_SUCCESS, 
	SELECT_SCHEDULE_COMPANY, 
	SELECT_SCHEDULE_COMPANY_FAILED, 
	SELECT_SCHEDULE_COMPANY_SUCCESS
} from "./actionTypes";


function* selectScheduleCompany({payload}) {
	try {
		const { companyId } = payload;
		const response = yield call(selectScheduleCompanyFromDB, companyId);

		if(!response) {
			throw new Error('Company not found');
		}

		yield put({ type: SELECT_SCHEDULE_COMPANY_SUCCESS, payload: response });
	} catch (error) {
		yield put({ type: SELECT_SCHEDULE_COMPANY_FAILED, payload: error });
	}
}

function* createAppointment({payload}) {
	try {
		yield new Promise((resolve) => setTimeout(resolve, Math.floor(Math.random() * 500) + 500));

		const { companyId, data } = payload;

		const company = yield call(selectScheduleCompanyFromDB, companyId);

		if(!company) {
			throw new Error('Company not found');
		}

		const events = yield call(getEventsBySP, company.id, data.serviceProvider, { from: data.start.value, to: data.end.value });

		if(events.length) {
			yield put({ type: DOUBLE_BOOKING});
			yield put(toastr({
				title: "This time slot was taken while you were booking. Please try again with a different time slot.",
				type: "error"
			}));

			return;
		}

		let client = yield call(getClientByPhone, data.phone, company.id);
		if(!client) {
			const clientData = {
				address: "",
				cardId: "",
				city: "",
				email: data.email || "",
				name: data.name,
				note: "",
				phone: data.phone,
				totalDue: 0, 
				totalDuration: 0,
				totalEvents: 0,
				totalPaid: 0,
				totalPurchases: 0,
			};

			client = yield call(createClientInDB, clientData, null, company.id, true)
		}

		const appt = {
			client: client.id,
			clientName: data.name,
			date: format(data.start.value, "dd/MM/yyyy"),
			description: data.note,
			duration: data.end.value - data.start.value,
			end: data.end.value,
			paid: false,
			paidAmount: 0,
			paymentType: "",
			price: parseInt(data.price, 10),
			priceOverride: false,
			serviceProvider: data.serviceProvider,
			serviceProviderName: data.serviceProviderName,
			services: [
				{
					amount: 1,
					service: {
						label: data.serviceName,
						value: data.service,
					}
				}
			],
			start: data.start.value,
			status: "Requested",
			title: `${client.name} requested '${data.serviceName}' with ${data.serviceProviderName}`,
			type: "bg-primary-subtle",
		};

		yield call(createEventInDB, appt, null, company.id, true);
		yield put({ type: CREATE_SCHEDULER_APPOINTMENT_SUCCESS, payload: appt });
	} catch (error) {
		console.log(error);
		yield put({ type: CREATE_SCHEDULER_APPOINTMENT_FAILED });
	}
}

function* getSPUnavailableHours({payload}) {
	try {
		const {sId, spId, date} = payload

		const days = getVisibleDaysFromMonth(parseInt(format(date, "MM"), 10) - 1, parseInt(format(date, "yyyy"), 10));

		const from = days[0];
		const to = days[days.length - 1];

		const companySelector = state => state.Scheduler.company;
		const company = yield select(companySelector);

		const serviceSelector = state => state.Services.list.find((el) => el.id === sId);
		const service = yield select(serviceSelector);

		const events = yield call(getEventsBySP, company.id, spId, { from, to });

		const list = generateAvailabilityForEachDay(from, to, service.duration, company.workingHours, events);
		const key = format(date, "yyyy-MM");

		yield put({ type: GET_SP_UNAVAILABLE_HOURS_SUCCESS, payload: { list, spId, key }});
	} catch(err) {
		console.log(err);
		yield put({ type: GET_SP_UNAVAILABLE_HOURS_FAILED});
	}
}

function generateAvailabilityForEachDay(from, to, duration, wh, events) {
	const durationMinutes = parseInt(duration.minutes, 10) + (parseInt(duration.hours, 10) * 60);

	return checkForDisabledDays(generateDays(from, to, wh), durationMinutes, events);
}

function isDayAvailable(day, durationInMinutes, events) {
	day.events = events;

	if(!day.available) {
		return day;
	}

	if(!events.length) {
		return day;
	}

	day.available = false;
	const start = day.from;
	const end = day.to;
	
	events.sort((a, b) => a.start - b.start);


	if(Math.abs(differenceInMinutes(events[0].start, start)) >= durationInMinutes) {
		day.available = true;
		return day;
	}

	for(let i = 0; i < events.length - 1; i++) {
		const currentEnd = events[i].end;
		const nextStart = events[i+1].start;

		if(Math.abs(differenceInMinutes(currentEnd, nextStart)) >= durationInMinutes) {
			day.available = true;
			break;
		}
	}

	if(Math.abs(differenceInMinutes(end, events[events.length - 1].end)) >= durationInMinutes) {
		day.available = true;
		return day;
	}

	return day;
}

function checkForDisabledDays(list, duration, events) {
	list.forEach((day) => {
		const eventsForDay = events.filter((event) => format(event.start, "dd/MM/yyyy") === format(day.date, "dd/MM/yyyy"));

		return isDayAvailable(day, duration, eventsForDay);
	});

	return list;
}

function generateDays(from, to, workingHours) {
	const days = [];
	let currentDate = from;

	if (isBefore(currentDate, new Date())) {
		currentDate = new Date();
	}

	while(!isAfter(currentDate, to)) {
		//eslint-disable-next-line
		const wh = workingHours.find((el) => el.day === format(currentDate, "EEEE"));

		const day = {
			available: !wh.closed,
			date: currentDate,
		}

		if(day.available) {
			day.from = dateFromTime(wh.start).setDate(currentDate.getDate());
			day.to = dateFromTime(wh.end).setDate(currentDate.getDate());
		}

		days.push(day);
		currentDate = addDays(currentDate, 1);
	}

	return days;
}

function dateFromTime(time) {
	const date = new Date();
	date.setHours(time.split(":")[0]);
	date.setMinutes(time.split(":")[1]);
	date.setSeconds(0);
	date.setMilliseconds(0);

	return date;
}

export default function* schedulerSaga() {
	yield takeEvery(SELECT_SCHEDULE_COMPANY, selectScheduleCompany);
	yield takeEvery(CREATE_SCHEDULER_APPOINTMENT, createAppointment);
	yield takeEvery(GET_SP_UNAVAILABLE_HOURS, getSPUnavailableHours);
}
