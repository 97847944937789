import { CREATE_DEPARTMENT, DELETE_DEPARTMENT, GET_DEPARTMENTS, UPDATE_DEPARTMENT } from "./actionTypes";

export const getDepartments = (options = {}) => {
	const { search = '', company = '' } = options;
	return {
		type: GET_DEPARTMENTS,
		payload: {
			search,
			company
		},
	}
}

export const createDepartment = (data, company = '') => {
	return {
		type: CREATE_DEPARTMENT,
		payload: {
			data,
			company,
		},
	}
}

export const updateDepartment = (id, data, originalData, company = '') => {
	return {
		type: UPDATE_DEPARTMENT,
		payload: {
			id,
			data,
			originalData,
			company,
		},
	}
}

export const deleteDepartment = (id, company = '') => {
	return {
		type: DELETE_DEPARTMENT,
		payload: {
			id,
			company,
		},
	}
}
