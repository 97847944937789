import {
	CREATE_GROUP,
	CREATE_GROUP_FAILURE,
	CREATE_GROUP_SUCCESS,
	DELETE_GROUP,
	DELETE_GROUP_FAILURE,
	DELETE_GROUP_SUCCESS,
	GET_GROUPS,
	GET_GROUPS_FAILURE,
	GET_GROUPS_SUCCESS,
	GET_GROUP_ATTENDEES,
	GET_GROUP_ATTENDEES_SUCCESS,
	UPDATE_GROUP,
	UPDATE_GROUP_FAILURE,
	UPDATE_GROUP_SUCCESS
} from "./actionTypes";

const defaultState = {
	list: [],
	loading: false,
	loadingCreate: false,
	finishedCreate: false,
	loadingUpdate: false,
	loadingDelete: false,
	error: false,
}

const groupsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_GROUPS:
			return {
				...state,
				loading: true,
				finishedCreate: false,
				error: false,
		}

		case GET_GROUPS_SUCCESS:
			return {
				...state,
				list: action.payload,
				loading: false,
				error: false,
		}

		case GET_GROUPS_FAILURE:
			return {
				...state,
				loading: false,
				error: true,
		}

		case CREATE_GROUP:
			return {
				...state,
				loadingCreate: true,
				error: false,
		}

		case CREATE_GROUP_SUCCESS:
			return {
				...state,
				loadingCreate: false,
				finishedCreate: true,
				error: false,
		}

		case CREATE_GROUP_FAILURE:
			return {
				...state,
				loadingCreate: false,
				error: true,
		}

		case UPDATE_GROUP:
			return {
				...state,
				loadingUpdate: true,
				error: false,
		}

		case UPDATE_GROUP_SUCCESS:
			return {
				...state,
				loadingUpdate: false,
				error: false,
		}

		case UPDATE_GROUP_FAILURE:
			return {
				...state,
				loadingUpdate: false,
				error: false,
		}

		case DELETE_GROUP:
			return {
				...state,
				loadingDelete: true,
				error: false,
		}

		case DELETE_GROUP_SUCCESS:
			return {
				...state,
				list: state.list.filter((group) => group.ID !== action.payload),
				loadingDelete: false,
				error: false,
		}

		case DELETE_GROUP_FAILURE:
			return {
				...state,
				loadingDelete: false,
				error: true,
		}

		case GET_GROUP_ATTENDEES:
			return {
				...state,
				loading: true,
				error: false,
		}

		case GET_GROUP_ATTENDEES_SUCCESS:
			return {
				...state,
				list: state.list.map((group) => {
					if(group.id === action.payload.groupId) {
						return {
							...group,
							attendees: action.payload.attendees.map((attendee) => ({
								value: attendee.id,
								label: attendee.name
							}))
						}
					}

					return group;
				}),
				loading: false,
				error: false,
		}

		default:
			return state
	}
}

export default groupsReducer;

