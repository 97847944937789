import { add } from "date-fns";
import {getFirebaseBackend} from "./firebase_helper";

const firebase = getFirebaseBackend().firebase;

export const addSubscriptionToGroupInDB = async (company, data) => {
	if(!company) return Promise.reject('Company is required');
	if(!data.clientId) return Promise.reject('Client is required');
	if(!data.groupId) return Promise.reject('Group is required');
	if(!data.classId) return Promise.reject('Class is required');
	if(!data.startDate) return Promise.reject('Start date is required');
	if(!data.pricingModel) return Promise.reject('Pricing model is required');
	if(!data.clientName) return Promise.reject('Client name is required');

	const subscriptionRef = firebase.firestore().collection("subscriptions").doc();
	const now = firebase.firestore.Timestamp.now();

	const groupRef = firebase.firestore().collection("companies").doc(company).collection('groups').doc(data.groupId);

	await groupRef.update({
		subscriptions: firebase.firestore.FieldValue.increment(1)
	});

	data = {
		...data,
		...data.pricingModel,
		createdAt: now,
		companyId: company
	}

	const nextBillingDate = add(data.startDate, {[data.period]: parseInt(data.frequency)});

	delete data.pricingModel;
	data.nextBillingDate = nextBillingDate;
	data.lastBilledDate = null;

	await subscriptionRef.set(data);

	return {
		id: subscriptionRef.id,
		...data
	};
}

export const getSubscriptionModelsForClassFromDB = async (company, classId) => {
	if(!company) return Promise.reject('Company is required');

	const classData = await firebase.firestore().collection("companies").doc(company).collection('classes').doc(classId).get();

	return classData.data();
}

export const createAttendanceForGroupOnEventInDB = async (company, eventId, data) => {
	if(!company) return Promise.reject('Company is required');

	const attendanceRef = firebase.firestore().collection("companies").doc(company).collection("events").doc(eventId).collection('attendance').doc();

	await attendanceRef.set(data);

	return {
		id: attendanceRef.id,
		...data
	};
}

export const getAttendanceForGroupOnEventFromDB = async (company, eventId, groupId, eventStart) => {
	if(!company) return Promise.reject('Company is required');

	return []
}

export const getSubscriptionsForGroupFromDB = async (company, groupId) => {
	if(!company) return Promise.reject('Company is required');

	const subscriptions = await firebase.firestore().collection("subscriptions").where('groupId', '==', groupId).where("companyId", "==", company).get();

	return subscriptions.docs.map((doc) => {
		return {
			id: doc.id,
			...doc.data(),
		}
	});
}
